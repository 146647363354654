import type { HandleClientError } from '@sveltejs/kit';
import Logger from '$lib/logger';

export const handleError: HandleClientError = ({ error, event }) => {
  Logger.error(error, event);

  return {
    message: 'Sorry, something went wrong',
  };
};
