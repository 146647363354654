import type { Handle } from '@sveltejs/kit';

type Method = 'get' | 'put' | 'patch' | 'post' | 'delete';

export type MockHandler = (
  req: Request,
  params: Record<string, string>,
) => Response | Promise<Response>;
export interface MockDefinition {
  method: Method;
  url: RegExp;
  params: string[];
  handler: MockHandler;
}

const definitionCreator = (method: Method) => {
  return (url: string, handler: MockHandler): MockDefinition => {
    const params = [];
    const urlRegExp = url.replace(/:[\w]+/g, (match) => {
      params.push(match.slice(1));

      return '([\\w-_%+]+)';
    });

    return {
      method,
      url: new RegExp(`^${urlRegExp}$`),
      params,
      handler,
    };
  };
};

export const rest = {
  get: definitionCreator('get'),
  put: definitionCreator('put'),
  post: definitionCreator('post'),
  patch: definitionCreator('patch'),
  delete: definitionCreator('delete'),
};

export const resolveMockForRequest = (
  request: Request,
  url: URL,
  definitions: MockDefinition[],
): Response | Promise<Response> | undefined => {
  for (const mockDefinition of definitions) {
    const matches = url.pathname.match(mockDefinition.url);

    if (matches === null) continue;
    if (mockDefinition.method !== request.method.toLowerCase()) continue;

    const params = mockDefinition.params.reduce(
      (record, param, index) => {
        record[param] = matches[index + 1];
        return record;
      },
      {} as Record<string, string>,
    );

    return mockDefinition.handler(request, params);
  }
};

export const mockGlobalFetch = (definitions: MockDefinition[]) => {
  const originalFetch = globalThis.fetch;

  globalThis.fetch = async (url: RequestInfo | URL | string, init) => {
    if (typeof url === 'string' && !url.startsWith('http')) {
      url = 'http://localhost' + url;
    }

    const request = new Request(url, init);

    const mockResponse = resolveMockForRequest(request, new URL(request.url), definitions);

    if (!mockResponse) throw new Error(`No mock found for request: ${request.url}`);

    return mockResponse;
  };

  return originalFetch;
};

export const mockHooks = (options: {
  enabled: boolean;
  definitions: MockDefinition[];
}): { handle: Handle } => {
  return {
    handle: ({ event, resolve }) => {
      if (!options.enabled) return resolve(event);

      const mockResponse = resolveMockForRequest(event.request, event.url, options.definitions);

      return mockResponse ?? resolve(event);
    },
  };
};
