import {
  HARDWARE_KIND_LABEL,
  HARDWARE_LABEL,
  HARDWARE_WALLET_DEVICE_ID_ANNOTATION,
  HARDWARE_WALLET_HD_PATH_ANNOTATION,
  HARDWARE_WALLET_PRODUCT_ID_ANNOTATION,
  HARDWARE_WALLET_PRODUCT_NAME_ANNOTATION,
} from '$lib/models/blockchain-account';
import { rest } from '../../hooks/mocks';
import * as transactions from './transactions';
import * as transactionsApprovals from './transactions-approvals';
import * as transactionsOpensea from './transactions-opensea';
import * as transactionsTransfers from './transactions-transfers';
import * as transactionWarnings from './transactions-warnings';

export const allTransactions = [
  ...Object.values(transactions),
  ...Object.values(transactionsApprovals),
  ...Object.values(transactionsOpensea),
  ...Object.values(transactionsTransfers),
  ...Object.values(transactionWarnings),
];

const transactionIds = new Set();

for (const t of allTransactions) {
  const { id } = t.transaction;

  if (transactionIds.has(id)) {
    throw new Error(`Duplicate mock transaction ID: ${id}`);
  }

  transactionIds.add(id);
}

const res = (status: number, json?: unknown) => {
  return new Response(json ? JSON.stringify(json) : '', {
    status,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const definitions = [
  rest.get('/api/v1/transactions/:transactionId', (_req, params) => {
    const transaction = allTransactions.find((t) => t.transaction.id === params.transactionId);

    if (transaction) {
      return res(200, transaction);
    }

    return res(404);
  }),

  rest.get('/api/v2/blockchain-accounts', () => {
    return res(200, {
      accounts: [
        {
          id: '6698c6e3-7208-4b1b-8dba-82ed3bc34ef1',
          profileId: '646b15aa-30c5-4ec9-94e2-019c64de5643',
          displayName: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
          kind: 'bitski',
          coinType: 60,
          address: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
          annotations: {},
          labels: {},
          createdAt: '2022-06-18T00:10:48.129140',
          updatedAt: '2022-06-18T00:10:48.129140',
        },
        {
          id: '6698c6e3-7208-4b1b-8dba-82ed3bc34ef1',
          profileId: '646b15aa-30c5-4ec9-94e2-019c64de5643',
          displayName: 'My Self-Custody',
          kind: 'view',
          coinType: 60,
          address: '0x3e5296ea36b079265875e52a6eb67799da724ac7',
          annotations: {},
          labels: {
            'custody.bits.ki/self': 'true',
          },
          createdAt: '2022-06-18T00:10:48.129140',
          updatedAt: '2022-06-18T00:10:48.129140',
        },
        {
          id: 'cb7d6a38-5ce4-49b7-bb5e-68d7700d9604',
          profileId: '646b15aa-30c5-4ec9-94e2-019c64de5643',
          displayName: 'Ledger Nano X',
          kind: 'view',
          coinType: 60,
          address: '0xf1006a889955d8Be4a3ba50c5fCa04F593d1DF20',
          annotations: {
            [HARDWARE_WALLET_DEVICE_ID_ANNOTATION]: '0001',
            [HARDWARE_WALLET_PRODUCT_ID_ANNOTATION]: '16405',
            [HARDWARE_WALLET_PRODUCT_NAME_ANNOTATION]: 'Ledger Nano X',
            [HARDWARE_WALLET_HD_PATH_ANNOTATION]: "m/44'/60'/0'/0/0",
          },
          labels: {
            [HARDWARE_LABEL]: 'true',
            [HARDWARE_KIND_LABEL]: 'ledger',
          },
          createdAt: '2022-06-18T00:10:48.129140',
          updatedAt: '2022-06-18T00:10:48.129140',
        },
        {
          id: '81251739-a2c1-4e2b-86c5-9fbe169ff97b',
          profileId: '646b15aa-30c5-4ec9-94e2-019c64de5643',
          displayName: 'Ledger Nano X 2',
          kind: 'view',
          coinType: 60,
          address: '0x4F9dECE14d09B02d4924E76d4B26Dd9D853d0773',
          annotations: {
            [HARDWARE_WALLET_DEVICE_ID_ANNOTATION]: '0001',
            [HARDWARE_WALLET_PRODUCT_ID_ANNOTATION]: '16405',
            [HARDWARE_WALLET_PRODUCT_NAME_ANNOTATION]: 'Ledger Nano X',
            [HARDWARE_WALLET_HD_PATH_ANNOTATION]: "m/44'/60'/1'/0/0",
          },
          labels: {
            [HARDWARE_LABEL]: 'true',
            [HARDWARE_KIND_LABEL]: 'ledger',
          },
          createdAt: '2022-06-18T00:10:48.129140',
          updatedAt: '2022-06-18T00:10:48.129140',
        },
        {
          id: 'bd74134a-6b3f-4ae4-b038-12af1d7ff424',
          profileId: '689eced8-743d-49dc-b4ae-58b4a419edf3',
          displayName: 'My Safe',
          kind: 'contract-wallet',
          coinType: 60,
          address: '0x9082269eeb932d7e697dbdf62d6b2d9fae4302c3',
          annotations: {
            'wallet.bitski.com/isGnosisSafe': 'true',
            'wallet.bitski.com/icon': 'Shark',
          },
          labels: {
            'wallet.bitski.com/account-abstraction-account-owner':
              '0xaa1a54bfa93be8ac8d01182827433f227ba59e17',
            'wallet.bitski.com/account-abstraction-account-manager':
              '0x34D26E0E757931421Ba120B05269DC475901FFc9',
            'wallet.bitski.com/account-abstraction-salt':
              '8f08db1966ac918c1ff2b7118f05612334d7c7e554f9875cb4b8f5dcbfdcd250',
            'wallet.bitski.com/account-abstraction-account-factory':
              '0xf7663424dd137d51287b199f372bbbdc136f77d0',
            'wallet.bitski.com/account-abstraction-entrypoint':
              '0x5FF137D4b0FDCD49DcA30c7CF57E578a026d2789',
            'wallet.bitski.com/account-abstraction-account-initial-owner':
              '0x7cc7c398fcabfacd206a73a63494a9cd50e26697',
          },
          createdAt: '2023-06-26T17:52:19.883181',
          updatedAt: '2023-11-30T22:08:44.867600',
        },
      ],
    });
  }),

  rest.post('/api/v2/blockchain-accounts', async (req) => {
    const json = (await req.json()) as object;
    return res(200, {
      ...json,
      id: 'some-id',
      profileId: 'some-id',
      chainId: 1,
      coinType: 60,
      createdAt: Date.now().toString(),
      updatedAt: Date.now().toString(),
    });
  }),

  rest.patch('/api/v2/blockchain-accounts/:accountId', async (req, params) => {
    const json = (await req.json()) as object;
    return res(200, {
      ...json,
      id: params.accountId,
      profileId: 'some-id',
      chainId: 1,
      coinType: 60,
      createdAt: Date.now().toString(),
      updatedAt: Date.now().toString(),
    });
  }),

  rest.post('/api/v1/transactions/:transactionId/consents', () => {
    return res(200, {});
  }),

  rest.get('/api/v1/web3/:chainId/:methodName', (req, params) => {
    if (params.methodName === 'eth_blockNumber') {
      return res(200, { result: '0x1' });
    } else if (params.methodName === 'eth_getBlockByNumber') {
      return res(200, { result: { number: '0x1' } });
    }

    return res(500);
  }),

  rest.get('/api/v1/gasprice/:chainId', () => {
    return res(200, {
      gasPrices: [
        {
          maxFeePerGas: 101.73,
          maxPriorityFeePerGas: 0.28,
          price: 81,
          speed: 'FAST',
          unit: 'gwei',
        },
        {
          maxFeePerGas: 101.61,
          maxPriorityFeePerGas: 0.16,
          price: 81,
          speed: 'MEDIUM',
          unit: 'gwei',
        },
        {
          maxFeePerGas: 101.45,
          maxPriorityFeePerGas: 0.0,
          price: 80,
          speed: 'SLOW',
          unit: 'gwei',
        },
      ],
      quoteUSD: 1850.182969363974,
    });
  }),

  rest.post('/api/v1/web3/:chainId', async (req) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { method } = (await req.json()) as any;

    const min = Math.ceil(100);
    const max = Math.floor(10000);
    const gas = Math.floor(Math.random() * (max - min + 1) + min);

    switch (method) {
      case 'eth_gasPrice':
        return res(200, { jsonrpc: '2.0', result: '0x1000000000', id: 1 });
      case 'eth_getBalance':
        return res(200, { jsonrpc: '2.0', result: '0x10a6f4bba7364a4', id: 48 });
      case 'eth_accounts':
        return res(200, { jsonrpc: '2.0', result: ['0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF'] });
      case 'eth_blockNumber':
        return res(200, { jsonrpc: '2.0', result: '0x1' });
      case 'eth_getBlockByNumber':
        return res(200, {
          jsonrpc: '2.0',
          result: {
            baseFeePerGas: '0x575ac2e79',
            extraData:
              '0xd682030383626f7288676f312e31392e34856c696e757800000000000000000073c2bd0688b88c5b970ee108363b8a829d7a78e16b125b824d1398d02b1d5feb50910739436599ba56dbef5489d39f60f88f535fdabbb1a486a7c28fd769fe7501',
            gasLimit: '0x1c6a206',
            gasUsed: '0xbc9b36',
            hash: '0xffd6f709579ad05747e2d966e949c46a8ff5942e70bbbd0b3ce595d213e856d8',
            number: '0x2484f68',
            parentHash: '0x91d41f74f19d13118f8181861fcaba7f7bbe9600c56f21069ce9f1c9d0004a05',
            timestamp: '0x63ca00f7',
          },
        });
      case 'eth_estimateGas':
        return res(200, { jsonrpc: '2.0', result: `0x${gas.toString(16)}`, id: 42 });
      case 'eth_getCode':
        return res(200, { jsonrpc: '2.0', result: '0x', id: 42 });
      default:
        return res(200, { jsonrpc: '2.0', result: '0x1', id: 42 });
    }
  }),

  rest.post('/api/v1/web3/chains/:chainId', () => {
    return res(200, { jsonrpc: '2.0', result: '0x1', id: 42 });
  }),
];
