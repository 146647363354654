export const ethSignTransactionCustomRpc = {
  clients: [
    {
      client_id: '30f883ce-e3b7-4f40-be5e-76a5663176de',
      client_name: 'Modular Games',
      client_uri: 'https://modulargames.xyz',
      logo_uri: '',
      owner: '30f883ce-e3b7-4f40-be5e-76a5663176de',
      policy_uri: '',
      tos_uri: '',
    },
  ],
  transaction: {
    activity: {
      additionalEvents: [],
      primaryEvent: {
        eventType: 'UNKNOWN',
      },
    },
    clientId: '30f883ce-e3b7-4f40-be5e-76a5663176de',
    consentStatus: 'INCOMPLETE',
    context: {
      activity: {
        additionalEvents: [],
        associatedAddresses: [],
        primaryEvent: {
          eventType: 'UNKNOWN',
        },
      },
      chainId: 20482050,
      rpcUrl: 'https://testnet.modulargames.xyz',
    },
    contractInfo: {
      isContract: false,
      isEtherscanVerified: false,
    },
    id: 'eth-sign-transaction-custom-rpc-url',
    kind: 'ETH_SIGN_TRANSACTION',
    payload: {
      data: '0xedfd80600000000000000000000000005721e3983b19712ba236337f637df900a143f3f9000000000000000000000000dd283ee05f836ac2dd47380e8742c0283c1bc3c8000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000221d49960000000000000000000000000000000000000000000000000000000000000000',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      gasPrice: '0x2710',
      to: '0xF3226739506f1734ddA7dd12E85A2Dcac1ae8D6F',
      value: '0x2d79883d2000',
    },
    submitterId: '74678520-7d89-493d-b22f-bad9c314b2cf',
    warnings: [],
  },
};

export const ethSign = {
  transaction: {
    id: 'eth-sign',
    kind: 'ETH_SIGN',
    consentStatus: 'INCOMPLETE',
    payload: {
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      message:
        '0x57656c636f6d6520746f204f70656e536561210a0a436c69636b20746f207369676e20696e20616e642061636365707420746865204f70656e536561205465726d73206f6620536572766963653a2068747470733a2f2f6f70656e7365612e696f2f746f730a0a5468697320726571756573742077696c6c206e6f742074726967676572206120626c6f636b636861696e207472616e73616374696f6e206f7220636f737420616e792067617320666565732e0a0a596f75722061757468656e7469636174696f6e207374617475732077696c6c20726573657420616674657220323420686f7572732e0a0a57616c6c657420616464726573733a0a3078376363376333393866636162666163643230366137336136333439346139636435306532363639370a0a4e6f6e63653a0a64656630313032642d343561652d346335622d626162652d343564663064333932646436',
    },
    activity: {
      primaryEvent: {
        eventType: 'UNKNOWN',
      },
      additionalEvents: [],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};

export const ethSignTransactionBinance = {
  clients: [
    {
      client_id: 'f6b5b5b8-9a1c-49a3-b61e-6925736490c5',
      client_name: 'Bitski Dev Preview',
      client_uri: 'https://dev-preview.bitski.workers.dev',
      logo_uri: '',
      owner: 'f6b5b5b8-9a1c-49a3-b61e-6925736490c5',
      policy_uri: '',
      tos_uri: '',
    },
  ],
  transaction: {
    clientId: 'f6b5b5b8-9a1c-49a3-b61e-6925736490c5',
    context: {
      chainId: 56,
      currentBalance: '0x213217539ebc000',
    },
    id: 'eth-sign-transaction-binance',
    kind: 'ETH_SIGN_TRANSACTION',
    consentStatus: 'INCOMPLETE',
    payload: {
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      gas: '0x5208',
      gasPrice: '0x12a05f200',
      nonce: '0x0',
      to: '0xaa1a54bfa93be8ac8d01182827433f227ba59e17',
      value: '0x470de4df820000',
    },
    submitterId: '74678520-7d89-493d-b22f-bad9c314b2cf',
    activity: {
      primaryEvent: {
        eventType: 'UNKNOWN',
      },
      additionalEvents: [],
    },
  },
};

export const ethSignTransactionArbitrum = {
  clients: [
    {
      client_id: 'f6b5b5b8-9a1c-49a3-b61e-6925736490c5',
      client_name: 'Bitski Dev Preview',
      client_uri: 'https://dev-preview.bitski.workers.dev',
      logo_uri: '',
      owner: 'f6b5b5b8-9a1c-49a3-b61e-6925736490c5',
      policy_uri: '',
      tos_uri: '',
    },
  ],
  transaction: {
    clientId: 'f6b5b5b8-9a1c-49a3-b61e-6925736490c5',
    context: {
      chainId: 42161,
      currentBalance: '0x213217539ebc000',
    },
    id: 'eth-sign-transaction-arbitrum',
    kind: 'ETH_SIGN_TRANSACTION',
    consentStatus: 'INCOMPLETE',
    payload: {
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      gas: '0x5208',
      gasPrice: '0x12a05f200',
      nonce: '0x0',
      to: '0xaa1a54bfa93be8ac8d01182827433f227ba59e17',
      value: '0x470de4df820000',
    },
    submitterId: '74678520-7d89-493d-b22f-bad9c314b2cf',
    activity: {
      primaryEvent: {
        eventType: 'UNKNOWN',
      },
      additionalEvents: [],
    },
  },
};

export const ethSignTransaction = {
  clients: [
    {
      client_id: '034bfea4-af92-4165-a9ca-fb3c7e39193d',
      client_name: 'Bitski',
      client_uri: '',
      logo_uri:
        'https://cdn.bitskistatic.com/clients/8cc43503-9ebd-4588-a77f-51ae160d6934/b0cbf12d-dea9-4ee9-8e21-170943ca067c.png',
      owner: '',
      policy_uri: '',
      tos_uri: '',
    },
  ],
  transaction: {
    clientId: '034bfea4-af92-4165-a9ca-fb3c7e39193d',
    context: { chainId: 1, currentBalance: '0x5701143de66cd7' },
    contractInfo: { isContract: true, isOpenseaVerified: false, isEtherscanVerified: true },
    id: 'eth-sign-transaction',
    kind: 'ETH_SIGN_TRANSACTION',
    payload: {
      data: '0xa9059cbb000000000000000000000000aa1a54bfa93be8ac8d01182827433f227ba59e170000000000000000000000000000000000000000000000000de0b6b3a7640000',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      gas: '0x9028',
      nonce: '0x6f',
      to: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
    },
    submitterId: '74678520-7d89-493d-b22f-bad9c314b2cf',
    activity: {
      primaryEvent: {
        eventType: 'TOKEN_TRANSFER',
        details: {
          from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
          to: '0xaa1a54bfa93be8ac8d01182827433f227ba59e17',
          kind: 'TOKEN',
          token: {
            coinType: 60,
            chainId: 1,
            contractAddress: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
            collection: {
              name: 'Matic Token',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-polygon-square-8db3352b9176cc20d232fd9154a5dbef7186920e454fc7199b1717ed362212d9.png',
              isOpenseaVerified: false,
              isEtherscanVerified: true,
            },
            decimals: 18,
            symbol: 'MATIC',
          },
          amount: '1000000000000000000',
          blockNumber: 0,
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
          logIndex: null,
        },
      },
      additionalEvents: [
        {
          eventType: 'TOKEN_TRANSFER',
          details: {
            from: '0x7cc7c398fcabfacd206a73a63494a9cd50e26697',
            to: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
            kind: 'TOKEN',
            token: {
              coinType: 60,
              chainId: 1,
              contractAddress: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
              collection: {
                name: 'Matic Token',
                image:
                  'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-polygon-square-8db3352b9176cc20d232fd9154a5dbef7186920e454fc7199b1717ed362212d9.png',
                isOpenseaVerified: false,
                isEtherscanVerified: true,
              },
              decimals: 18,
              symbol: 'MATIC',
            },
            amount: '-1000000000000000000',
            blockNumber: 0,
            transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
            logIndex: null,
          },
        },
      ],
    },
    consentStatus: 'INCOMPLETE',
    warnings: [],
  },
};

export const ethSignTransactionInsufficient = {
  transaction: {
    id: 'eth-sign-transaction-insufficient',
    kind: 'ETH_SIGN_TRANSACTION',
    consentStatus: 'INCOMPLETE',
    payload: {
      to: '0x3e5296ea36b079265875e52a6eb67799da724ac7',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      value: '0xde0b6b3a7640000',
      data: '0x',
      gas: '0x2ee0',
      gasPrice: '0x77359400',
    },
    context: {
      chainId: 5,
      origin: 'https://blur.io',
    },
    activity: {
      primaryEvent: {
        eventType: 'UNKNOWN',
      },
      additionalEvents: [],
    },
  },
  clients: [
    {
      client_id: '5bc0d0c2-cd6a-44a6-886b-f3f111008596',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};

export const ethSignTypedDataTransaction = {
  clients: [
    {
      client_id: '522e54fd-c85a-4bf5-8f06-6aecfa90fe7c',
      client_name: 'Bitski Wallet Extension',
      client_uri: 'https://www.bitski.com',
      logo_uri: '',
      owner: '522e54fd-c85a-4bf5-8f06-6aecfa90fe7c',
      policy_uri: '',
      tos_uri: '',
    },
  ],
  transaction: {
    clientId: '522e54fd-c85a-4bf5-8f06-6aecfa90fe7c',
    context: { chainId: 0, from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF' },
    contractInfo: { isContract: false, isOpenseaVerified: false, isEtherscanVerified: false },
    id: 'eth-sign-typed-data-transaction',
    kind: 'ETH_SIGN_TYPED_DATA_V4',
    payload: {
      message: {
        data: '0x0000000000000000000000000000000000000000000000000000000000000020000000000000000000000000000000000000000000000000000000000000006000000000000000000000000000000000000000000000000000000000000000800000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000010000000000000000000000001cf0df2a5a20cd61d68d4489eebbf85b8d39e18a0000000000000000000000000000000000000000000000000000000000000064',
        dataType: '0x23d235ef',
        end: '0x638fbbac',
        makeAsset: {
          assetType: {
            assetClass: '0x8ae85d84',
            data: '0x000000000000000000000000c02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
          },
          value: '0x2c68af0bb140000',
        },
        maker: '0x7cc7c398fcabfacd206a73a63494a9cd50e26697',
        salt: '0xd0e5f28f541836f80754aadc0a78df5e0a38f85d9a785049c64aa97b9542099',
        start: '0x0',
        takeAsset: {
          assetType: {
            assetClass: '0x973bb640',
            data: '0x00000000000000000000000033fd426905f149f8376e227d0c9d3340aad17af1000000000000000000000000000000000000000000000000000000000000001a',
          },
          value: '0x1',
        },
        taker: '0x0000000000000000000000000000000000000000',
      },
      types: {
        Asset: [
          { name: 'assetType', type: 'AssetType' },
          { name: 'value', type: 'uint256' },
        ],
        AssetType: [
          { name: 'assetClass', type: 'bytes4' },
          { name: 'data', type: 'bytes' },
        ],
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' },
        ],
        Order: [
          { name: 'maker', type: 'address' },
          { name: 'makeAsset', type: 'Asset' },
          { name: 'taker', type: 'address' },
          { name: 'takeAsset', type: 'Asset' },
          { name: 'salt', type: 'uint256' },
          { name: 'start', type: 'uint256' },
          { name: 'end', type: 'uint256' },
          { name: 'dataType', type: 'bytes4' },
          { name: 'data', type: 'bytes' },
        ],
      },
      primaryType: 'Order',
      domain: {
        chainId: '0x1',
        name: 'Exchange',
        verifyingContract: '0x9757f2d2b135150bbeb65308d4a91804107cd8d6',
        version: '2',
      },
    },
    submitterId: '74678520-7d89-493d-b22f-bad9c314b2cf',
    activity: { primaryEvent: { eventType: 'UNKNOWN' }, additionalEvents: [] },
    consentStatus: 'INCOMPLETE',
    warnings: [],
  },
};

export const ethSignTypedDataTransactionFirstParty = {
  clients: [
    {
      client_id: '8cc43503-9ebd-4588-a77f-51ae160d6934',
      client_name: 'Bitski',
      client_uri: 'bitski://application/callback',
      logo_uri: '',
      owner: '8cc43503-9ebd-4588-a77f-51ae160d6934',
      policy_uri: ' bitski://application/callback',
      tos_uri: ' bitski://application/callback',
    },
  ],
  transaction: {
    clientId: '8cc43503-9ebd-4588-a77f-51ae160d6934',
    context: {
      chainId: 0,
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
    },
    id: 'eth-sign-typed-data-transaction-first-party',
    kind: 'ETH_SIGNTYPEDDATA_V4',
    consentStatus: 'INCOMPLETE',
    payload: {
      domain: {
        chainId: 1,
        name: 'USD Coin',
        verifyingContract: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        version: '2',
      },
      message: {
        deadline: 1662585856,
        nonce: 0,
        owner: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
        spender: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
        value: '499991',
      },
      primaryType: 'Permit',
      types: {
        EIP712Domain: [
          {
            name: 'name',
            type: 'string',
          },
          {
            name: 'version',
            type: 'string',
          },
          {
            name: 'chainId',
            type: 'uint256',
          },
          {
            name: 'verifyingContract',
            type: 'address',
          },
        ],
        Permit: [
          {
            name: 'owner',
            type: 'address',
          },
          {
            name: 'spender',
            type: 'address',
          },
          {
            name: 'value',
            type: 'uint256',
          },
          {
            name: 'nonce',
            type: 'uint256',
          },
          {
            name: 'deadline',
            type: 'uint256',
          },
        ],
      },
    },
    activity: {
      primaryEvent: {
        eventType: 'UNKNOWN',
      },
      additionalEvents: [],
    },
    submitterId: '74678520-7d89-493d-b22f-bad9c314b2cf',
  },
};

export const ethSignSelfCustody = {
  transaction: {
    id: 'eth-sign-self-custody',
    kind: 'ETH_SIGN',
    consentStatus: 'INCOMPLETE',
    payload: {
      from: '0x3e5296ea36b079265875e52a6eb67799da724ac7',
      message:
        '0x57656c636f6d6520746f204f70656e536561210a0a436c69636b20746f207369676e20696e20616e642061636365707420746865204f70656e536561205465726d73206f6620536572766963653a2068747470733a2f2f6f70656e7365612e696f2f746f730a0a5468697320726571756573742077696c6c206e6f742074726967676572206120626c6f636b636861696e207472616e73616374696f6e206f7220636f737420616e792067617320666565732e0a0a596f75722061757468656e7469636174696f6e207374617475732077696c6c20726573657420616674657220323420686f7572732e0a0a57616c6c657420616464726573733a0a3078376363376333393866636162666163643230366137336136333439346139636435306532363639370a0a4e6f6e63653a0a64656630313032642d343561652d346335622d626162652d343564663064333932646436',
    },
    activity: {
      primaryEvent: {
        eventType: 'UNKNOWN',
      },
      additionalEvents: [],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};

export const ethSendContractWallet = {
  clients: [
    {
      client_id: '5bc0d0c2-cd6a-44a6-886b-f3f111008596',
      client_name: 'Native Client API',
      client_uri: '',
      logo_uri: '',
      owner: '',
      policy_uri: '',
      tos_uri: '',
    },
  ],
  transaction: {
    activity: {
      additionalEvents: [],
      primaryEvent: {
        eventType: 'UNKNOWN',
      },
    },
    clientId: '5bc0d0c2-cd6a-44a6-886b-f3f111008596',
    consentStatus: 'INCOMPLETE',
    context: {
      activity: {
        additionalEvents: [],
        associatedAddresses: [],
        primaryEvent: {
          eventType: 'UNKNOWN',
        },
      },
      chainId: 1,
      imageUrl: '',
      origin: 'chrome-extension://alfjiibllodfkljopianpekigmdegjjk',
    },
    contractInfo: {
      isContract: false,
      isEtherscanVerified: false,
    },
    id: 'eth-send-contract-wallet',
    kind: 'ETH_SEND_TRANSACTION',
    payload: {
      from: '0x9082269eeb932d7e697dbdf62d6b2d9fae4302c3',
      to: '0x9082269eeb932d7e697dbdf62d6b2d9fae4302c3',
      value: '0x0',
    },
    submitterId: '74678520-7d89-493d-b22f-bad9c314b2cf',
    warnings: [],
  },
};

export const ethSignHardwareWallet = {
  transaction: {
    id: 'eth-sign-hardware-wallet',
    kind: 'ETH_SIGN',
    consentStatus: 'INCOMPLETE',
    payload: {
      from: '0xf1006a889955d8Be4a3ba50c5fCa04F593d1DF20',
      message:
        '0x57656c636f6d6520746f204f70656e536561210a0a436c69636b20746f207369676e20696e20616e642061636365707420746865204f70656e536561205465726d73206f6620536572766963653a2068747470733a2f2f6f70656e7365612e696f2f746f730a0a5468697320726571756573742077696c6c206e6f742074726967676572206120626c6f636b636861696e207472616e73616374696f6e206f7220636f737420616e792067617320666565732e0a0a596f75722061757468656e7469636174696f6e207374617475732077696c6c20726573657420616674657220323420686f7572732e0a0a57616c6c657420616464726573733a0a3078376363376333393866636162666163643230366137336136333439346139636435306532363639370a0a4e6f6e63653a0a64656630313032642d343561652d346335622d626162652d343564663064333932646436',
    },
    activity: {
      primaryEvent: {
        eventType: 'UNKNOWN',
      },
      additionalEvents: [],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};
