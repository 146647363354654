import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10')
];

export const server_loads = [0];

export const dictionary = {
		"/(app)": [4,[2],[3]],
		"/(app)/activate/[address=address]/[transactionId=uuid]": [5,[2],[3]],
		"/(app)/cookie-permission": [6,[2],[3]],
		"/(hardware-import)/import-hardware-wallet": [~10],
		"/(app)/logout": [7,[2],[3]],
		"/(app)/popup-callback": [8,[2],[3]],
		"/(app)/transactions/[transactionId=uuid]": [9,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';