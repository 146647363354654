export const ethSignTransactionApprovalForAll = {
  transaction: {
    id: 'eth-sign-transaction-approval-for-all',
    kind: 'ETH_SIGN_TRANSACTION',
    consentStatus: 'INCOMPLETE',
    payload: {
      to: '0x3e5296ea36b079265875e52a6eb67799da724ac7',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      value: '0x0',
      data: '0xa22cb4650000000000000000000000001e0049783f008a0085193e00003d00cd54003c710000000000000000000000000000000000000000000000000000000000000001',
      gas: '0xd228',
      gasPrice: '0x2ca573f2c',
    },
    context: {
      chainId: 5,
    },
    activity: {
      primaryEvent: {
        eventType: 'TOKEN_APPROVAL',
        details: {
          owner: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
          spender: {
            contractAddress: '0x1e0049783f008a0085193e00003d00cd54003c71',
            chainId: 1,
            coinType: 60,
            name: 'NOT USED - get from metadata instead',
            collection: {
              description: 'Seaport conduit for handling approvals',
              name: 'OpenSea',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-opensea-f572b2cda3e44b404e6b67e7caedf2e71f24a387b37efa0fcfdf77a9359245b3.png',
              category: 'NFT_MARKETPLACE',
            },
          },
          token: {
            coinType: 60,
            chainId: 1,
            contractAddress: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
            collection: {
              description: '',
              name: 'CryptoCoven',
              image:
                'https://lh3.googleusercontent.com/E8MVasG7noxC0Fa_duhnexc2xze1PzT1jzyeaHsytOC4722C2Zeo7EhUR8-T6mSem9-4XE5ylrCtoAsceZ_lXez_kTaMufV5pfLc3Fk=s168',
              isOpenseaVerified: true,
              isEtherscanVerified: false,
              openseaFees: {},
            },
          },
          approvalType: 'APPROVAL_FOR_ALL',
          allowance: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
          blockNumber: 0,
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
          logIndex: null,
          blockTimestamp: '1970-01-01T00:00:00Z',
        },
      },
      additionalEvents: [],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};

export const ethSignTransactionApprovalForAllBare = {
  transaction: {
    id: 'eth-sign-transaction-approval-for-all-bare',
    kind: 'ETH_SIGN_TRANSACTION',
    consentStatus: 'INCOMPLETE',
    payload: {
      to: '0x3e5296ea36b079265875e52a6eb67799da724ac7',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      value: '0x0',
      data: '0xa22cb4650000000000000000000000001e0049783f008a0085193e00003d00cd54003c710000000000000000000000000000000000000000000000000000000000000001',
      gas: '0xd228',
      gasPrice: '0x2ca573f2c',
    },
    context: {
      chainId: 5,
    },
    activity: {
      primaryEvent: {
        eventType: 'TOKEN_APPROVAL',
        details: {
          owner: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
          spender: {
            chainId: 0,
            coinType: 60,
            contractAddress: '0x1e0049783f008a0085193e00003d00cd54003c71',
            collection: {
              description: 'Seaport conduit for handling approvals',
              name: 'OpenSea',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-opensea-f572b2cda3e44b404e6b67e7caedf2e71f24a387b37efa0fcfdf77a9359245b3.png',
              category: 'NFT_MARKETPLACE',
            },
          },
          token: {
            chainId: 0,
            coinType: 60,
            contractAddress: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
            collection: {},
          },
          approvalType: 'APPROVAL_FOR_ALL',
          allowance: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
          blockNumber: 0,
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
          logIndex: null,
          blockTimestamp: '1970-01-01T00:00:00Z',
        },
      },
      additionalEvents: [],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};

export const ethSignTransactionApprove = {
  transaction: {
    id: 'eth-sign-transaction-approve',
    kind: 'ETH_SIGN_TRANSACTION',
    consentStatus: 'INCOMPLETE',
    payload: {
      to: '0x3e5296ea36b079265875e52a6eb67799da724ac7',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      value: '0x0',
      data: '0xa22cb4650000000000000000000000001e0049783f008a0085193e00003d00cd54003c710000000000000000000000000000000000000000000000000000000000000001',
      gas: '0xd228',
      gasPrice: '0x2ca573f2c',
    },
    context: {
      chainId: 5,
    },
    activity: {
      primaryEvent: {
        eventType: 'TOKEN_APPROVAL',
        details: {
          owner: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
          spender: {
            contractAddress: '0x1e0049783f008a0085193e00003d00cd54003c71',
            chainId: 1,
            coinType: 60,
            name: 'NOT USED - get from metadata instead',
            collection: {
              description: 'Seaport conduit for handling approvals',
              name: 'OpenSea',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-opensea-f572b2cda3e44b404e6b67e7caedf2e71f24a387b37efa0fcfdf77a9359245b3.png',
              category: 'NFT_MARKETPLACE',
              isOpenseaVerified: true,
              isEtherscanVerified: false,
              openseaFees: {},
            },
          },
          token: {
            coinType: 60,
            chainId: 1,
            contractAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
            collection: {
              name: 'WETH',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-ethereum-1508bede72698750836584012a0062eff03ca341705b5eef8905826b4fee1d71.png',
              isOpenseaVerified: true,
              isEtherscanVerified: false,
              openseaFees: {},
            },
          },
          approvalType: 'APPROVE',
          allowance: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
          blockNumber: 0,
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
          logIndex: null,
          blockTimestamp: '1970-01-01T00:00:00Z',
        },
      },
      additionalEvents: [],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};

export const ethSignTransactionApproveBare = {
  transaction: {
    id: 'eth-sign-transaction-approve-bare',
    kind: 'ETH_SIGN_TRANSACTION',
    consentStatus: 'INCOMPLETE',
    payload: {
      to: '0x3e5296ea36b079265875e52a6eb67799da724ac7',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      value: '0x0',
      data: '0x095ea7b30000000000000000000000001e0049783f008a0085193e00003d00cd54003c71ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
      gas: '0x670c',
      gasPrice: '0x2900f8126',
    },
    context: {
      chainId: 5,
    },
    activity: {
      primaryEvent: {
        eventType: 'TOKEN_APPROVAL',
        details: {
          owner: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
          spender: {
            chainId: 1,
            coinType: 60,
            address: '0x1e0049783f008a0085193e00003d00cd54003c71',
            metadata: {
              description: 'Seaport conduit for handling approvals',
              name: 'OpenSea',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-opensea-f572b2cda3e44b404e6b67e7caedf2e71f24a387b37efa0fcfdf77a9359245b3.png',
              category: 'NFT_MARKETPLACE',
            },
          },
          token: {
            coinType: 60,
            chainId: 1,
            contractAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
            collection: {},
          },
          approvalType: 'APPROVE',
          allowance: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
          blockNumber: 0,
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
          logIndex: null,
          blockTimestamp: '1970-01-01T00:00:00Z',
        },
      },
      additionalEvents: [],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};

export const ethSignTransactionRevokeApprovalForAll = {
  transaction: {
    id: 'eth-sign-transaction-revoke-approval-for-all',
    kind: 'ETH_SIGN_TRANSACTION',
    consentStatus: 'INCOMPLETE',
    payload: {
      to: '0x3e5296ea36b079265875e52a6eb67799da724ac7',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      value: '0x0',
      data: '0xa22cb4650000000000000000000000001e0049783f008a0085193e00003d00cd54003c710000000000000000000000000000000000000000000000000000000000000000',
      gas: '0xd228',
      gasPrice: '0x2ca573f2c',
    },
    context: {
      chainId: 5,
    },
    activity: {
      primaryEvent: {
        eventType: 'TOKEN_APPROVAL',
        details: {
          owner: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
          spender: {
            address: '0x1e0049783f008a0085193e00003d00cd54003c71',
            chainId: 1,
            coinType: 60,
            name: 'NOT USED - get from metadata instead',
            metadata: {
              description: 'Seaport conduit for handling approvals',
              name: 'OpenSea',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-opensea-f572b2cda3e44b404e6b67e7caedf2e71f24a387b37efa0fcfdf77a9359245b3.png',
              category: 'NFT_MARKETPLACE',
            },
          },
          token: {
            coinType: 60,
            chainId: 1,
            contractAddress: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
            collection: {
              description: '',
              name: 'CryptoCoven',
              image:
                'https://lh3.googleusercontent.com/E8MVasG7noxC0Fa_duhnexc2xze1PzT1jzyeaHsytOC4722C2Zeo7EhUR8-T6mSem9-4XE5ylrCtoAsceZ_lXez_kTaMufV5pfLc3Fk=s168',
              isOpenseaVerified: true,
              isEtherscanVerified: false,
              openseaFees: {},
            },
          },
          approvalType: 'REVOKE_APPROVAL_FOR_ALL',
          allowance: '0x0',
          blockNumber: 0,
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
          logIndex: null,
          blockTimestamp: '1970-01-01T00:00:00Z',
        },
      },
      additionalEvents: [],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};

export const ethSignTransactionRevokeApprove = {
  transaction: {
    id: 'eth-sign-transaction-revoke-approve',
    kind: 'ETH_SIGN_TRANSACTION',
    consentStatus: 'INCOMPLETE',
    payload: {
      to: '0x3e5296ea36b079265875e52a6eb67799da724ac7',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      value: '0x0',
      data: '0x095ea7b300000000000000000000000034d85c9cdeb23fa97cb08333b511ac86e1c4e2580000000000000000000000000000000000000000000000000000000000000000',
      gas: '0xd228',
      gasPrice: '0x2ca573f2c',
    },
    context: {
      chainId: 5,
    },
    activity: {
      primaryEvent: {
        eventType: 'TOKEN_APPROVAL',
        details: {
          owner: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
          spender: {
            contractAddress: '0x1e0049783f008a0085193e00003d00cd54003c71',
            chainId: 1,
            coinType: 60,
            name: 'NOT USED - get from metadata instead',
            collection: {
              description: 'Seaport conduit for handling approvals',
              name: 'OpenSea',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-opensea-f572b2cda3e44b404e6b67e7caedf2e71f24a387b37efa0fcfdf77a9359245b3.png',
              category: 'NFT_MARKETPLACE',
            },
          },
          token: {
            coinType: 60,
            chainId: 1,
            contractAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
            collection: {
              name: 'WETH',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-ethereum-1508bede72698750836584012a0062eff03ca341705b5eef8905826b4fee1d71.png',
            },
          },
          approvalType: 'REVOKE_APPROVE',
          allowance: '0x0',
          blockNumber: 0,
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
          logIndex: null,
          blockTimestamp: '1970-01-01T00:00:00Z',
        },
      },
      additionalEvents: [],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};
