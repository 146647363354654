export const ethSignWarningsNonCritical = {
  clients: [
    {
      client_id: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
      client_name: 'Oauth app name',
      client_uri: 'app.com',
      logo_uri: 'app-logo.com',
      owner: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
      policy_uri: 'app-policy.com',
      tos_uri: 'app-tos.com',
    },
  ],
  transaction: {
    activity: {
      primaryEvent: {
        details: {
          allowance: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
          approvalType: 'APPROVAL_FOR_ALL',
          blockNumber: 0,
          blockTimestamp: '1970-01-01T00:00:00Z',
          logIndex: null,
          owner: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
          spender: {
            chainId: 1,
            coinType: 60,
            collection: {
              category: 'NFT_MARKETPLACE',
              description: 'Seaport conduit for handling approvals',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-opensea-f572b2cda3e44b404e6b67e7caedf2e71f24a387b37efa0fcfdf77a9359245b3.png',
              name: 'OpenSea',
              updatedAt: '2022-09-28T21:20:00',
            },
            contractAddress: '0x1e0049783f008a0085193e00003d00cd54003c71',
          },
          token: {
            chainId: 1,
            coinType: 60,
            collection: {
              category: 'NFT_MARKETPLACE',
              description:
                'Welcome to Candy! Shop our world-class NFTs here on OpenSea or at mlb.candy.com.\n\nCandy is Major League Baseball’s official NFT ecosystem where fans and collectors will be able to purchase, trade, and share officially licensed NFTs to deepen their love of the sport. Candy’s mission is to develop next-generation sports and culture digital assets, providing authentic objects and experiences that deepen fan engagement and connect people to their passions.',
              image:
                'https://i.seadn.io/gae/SwIlyjjg51ox7Xs6llIioZi2CixlNVFqa2ld23609CYLW7599HZxL3WQMJh0nLDQlYB20kISsEGhgz3MtcqB4uUHdkhB67buYOczAQ?w=500&auto=format',
              isEtherscanVerified: false,
              isOpenseaVerified: true,
              name: 'Candy Digital',
              openseaFees: {
                openseaFees: {
                  '0x0000a26b00c1f0df003000390027140000faa719': 250,
                },
                sellerFees: {
                  '0x329a0bb9f238a1e8bfad9a768924abcd4c1a1dbb': 1000,
                },
              },
              openseaSlug: 'candydigital',
              social: {
                discordUrl: 'https://discord.gg/6dVf3W8Z',
                externalUrl: 'https://www.candy.com/',
                instagramUsername: 'candydigital',
                mediumUsername: '',
                telegramUrl: '',
                twitterUsername: 'CandyDigital',
                wikiUrl: '',
              },
            },
            contractAddress: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
            decimals: 0,
            symbol: '',
          },
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
        },
        eventType: 'TOKEN_APPROVAL',
      },
      additionalEvents: [],
    },
    clientId: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
    consentStatus: 'INCOMPLETE',
    context: {
      chainId: 5,
    },
    id: 'eth-sign-warnings-non-critical',
    kind: 'ETH_SEND_TRANSACTION',
    payload: {
      data: '0xa22cb4650000000000000000000000001e0049783f008a0085193e00003d00cd54003c710000000000000000000000000000000000000000000000000000000000000001',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      to: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
    },
    submitterId: 'b7bf5543-cdd1-470d-8ac1-0800cf066362',
    warnings: [
      {
        kind: 'TRANSACTION',
        message: 'You are allowing this website to withdraw funds from your account in the future',
        severity: 'WARNING',
      },
    ],
  },
};

export const ethSignWarningsCritical = {
  clients: [
    {
      client_id: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
      client_name: 'Oauth app name',
      client_uri: 'app.com',
      logo_uri: 'app-logo.com',
      owner: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
      policy_uri: 'app-policy.com',
      tos_uri: 'app-tos.com',
    },
  ],
  transaction: {
    activity: {
      primaryEvent: {
        details: {
          allowance: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
          approvalType: 'APPROVAL_FOR_ALL',
          blockNumber: 0,
          blockTimestamp: '1970-01-01T00:00:00Z',
          logIndex: null,
          owner: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
          spender: {
            chainId: 1,
            coinType: 60,
            collection: {
              category: 'NFT_MARKETPLACE',
              description: 'Seaport conduit for handling approvals',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-opensea-f572b2cda3e44b404e6b67e7caedf2e71f24a387b37efa0fcfdf77a9359245b3.png',
              name: 'OpenSea',
              updatedAt: '2022-09-28T21:20:00',
            },
            contractAddress: '0x1e0049783f008a0085193e00003d00cd54003c71',
          },
          token: {
            chainId: 1,
            coinType: 60,
            collection: {
              category: 'NFT_MARKETPLACE',
              description:
                'Welcome to Candy! Shop our world-class NFTs here on OpenSea or at mlb.candy.com.\n\nCandy is Major League Baseball’s official NFT ecosystem where fans and collectors will be able to purchase, trade, and share officially licensed NFTs to deepen their love of the sport. Candy’s mission is to develop next-generation sports and culture digital assets, providing authentic objects and experiences that deepen fan engagement and connect people to their passions.',
              image:
                'https://i.seadn.io/gae/SwIlyjjg51ox7Xs6llIioZi2CixlNVFqa2ld23609CYLW7599HZxL3WQMJh0nLDQlYB20kISsEGhgz3MtcqB4uUHdkhB67buYOczAQ?w=500&auto=format',
              isEtherscanVerified: false,
              isOpenseaVerified: true,
              name: 'Candy Digital',
              openseaFees: {
                openseaFees: {
                  '0x0000a26b00c1f0df003000390027140000faa719': 250,
                },
                sellerFees: {
                  '0x329a0bb9f238a1e8bfad9a768924abcd4c1a1dbb': 1000,
                },
              },
              openseaSlug: 'candydigital',
              social: {
                discordUrl: 'https://discord.gg/6dVf3W8Z',
                externalUrl: 'https://www.candy.com/',
                instagramUsername: 'candydigital',
                mediumUsername: '',
                telegramUrl: '',
                twitterUsername: 'CandyDigital',
                wikiUrl: '',
              },
            },
            contractAddress: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
            decimals: 0,
            symbol: '',
          },
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
        },
        eventType: 'TOKEN_APPROVAL',
      },
      additionalEvents: [],
    },
    clientId: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
    consentStatus: 'INCOMPLETE',
    context: {
      chainId: 5,
    },
    id: 'eth-sign-warnings-critical',
    kind: 'ETH_SEND_TRANSACTION',
    payload: {
      data: '0xa22cb4650000000000000000000000001e0049783f008a0085193e00003d00cd54003c710000000000000000000000000000000000000000000000000000000000000001',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      to: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
    },
    submitterId: 'b7bf5543-cdd1-470d-8ac1-0800cf066362',
    warnings: [
      {
        kind: 'TRANSACTION',
        message: 'You are allowing this website to withdraw funds from your account in the future',
        severity: 'CRITICAL',
      },
    ],
  },
};

export const ethSignWarningsDomainsNonCritical = {
  clients: [
    {
      client_id: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
      client_name: 'Oauth app name',
      client_uri: 'app.com',
      logo_uri: 'app-logo.com',
      owner: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
      policy_uri: 'app-policy.com',
      tos_uri: 'app-tos.com',
    },
  ],
  transaction: {
    activity: {
      primaryEvent: {
        details: {
          allowance: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
          approvalType: 'APPROVAL_FOR_ALL',
          blockNumber: 0,
          blockTimestamp: '1970-01-01T00:00:00Z',
          logIndex: null,
          owner: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
          spender: {
            chainId: 1,
            coinType: 60,
            collection: {
              category: 'NFT_MARKETPLACE',
              description: 'Seaport conduit for handling approvals',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-opensea-f572b2cda3e44b404e6b67e7caedf2e71f24a387b37efa0fcfdf77a9359245b3.png',
              name: 'OpenSea',
              updatedAt: '2022-09-28T21:20:00',
            },
            contractAddress: '0x1e0049783f008a0085193e00003d00cd54003c71',
          },
          token: {
            chainId: 1,
            coinType: 60,
            collection: {
              category: 'NFT_MARKETPLACE',
              description:
                'Welcome to Candy! Shop our world-class NFTs here on OpenSea or at mlb.candy.com.\n\nCandy is Major League Baseball’s official NFT ecosystem where fans and collectors will be able to purchase, trade, and share officially licensed NFTs to deepen their love of the sport. Candy’s mission is to develop next-generation sports and culture digital assets, providing authentic objects and experiences that deepen fan engagement and connect people to their passions.',
              image:
                'https://i.seadn.io/gae/SwIlyjjg51ox7Xs6llIioZi2CixlNVFqa2ld23609CYLW7599HZxL3WQMJh0nLDQlYB20kISsEGhgz3MtcqB4uUHdkhB67buYOczAQ?w=500&auto=format',
              isEtherscanVerified: false,
              isOpenseaVerified: true,
              name: 'Candy Digital',
              openseaFees: {
                openseaFees: {
                  '0x0000a26b00c1f0df003000390027140000faa719': 250,
                },
                sellerFees: {
                  '0x329a0bb9f238a1e8bfad9a768924abcd4c1a1dbb': 1000,
                },
              },
              openseaSlug: 'candydigital',
              social: {
                discordUrl: 'https://discord.gg/6dVf3W8Z',
                externalUrl: 'https://www.candy.com/',
                instagramUsername: 'candydigital',
                mediumUsername: '',
                telegramUrl: '',
                twitterUsername: 'CandyDigital',
                wikiUrl: '',
              },
            },
            contractAddress: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
            decimals: 0,
            symbol: '',
          },
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
        },
        eventType: 'TOKEN_APPROVAL',
      },
      additionalEvents: [],
    },
    clientId: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
    consentStatus: 'INCOMPLETE',
    context: {
      chainId: 5,
    },
    id: 'eth-sign-warnings-domains-non-critical',
    kind: 'ETH_SEND_TRANSACTION',
    payload: {
      data: '0xa22cb4650000000000000000000000001e0049783f008a0085193e00003d00cd54003c710000000000000000000000000000000000000000000000000000000000000001',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      to: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
    },
    submitterId: 'b7bf5543-cdd1-470d-8ac1-0800cf066362',
    warnings: [
      {
        kind: 'DOMAIN',
        message: 'This site is flagged as malicious',
        severity: 'WARNING',
      },
    ],
  },
};

export const ethSignWarningsDomainsCritical = {
  clients: [
    {
      client_id: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
      client_name: 'Oauth app name',
      client_uri: 'app.com',
      logo_uri: 'app-logo.com',
      owner: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
      policy_uri: 'app-policy.com',
      tos_uri: 'app-tos.com',
    },
  ],
  transaction: {
    activity: {
      primaryEvent: {
        details: {
          allowance: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
          approvalType: 'APPROVAL_FOR_ALL',
          blockNumber: 0,
          blockTimestamp: '1970-01-01T00:00:00Z',
          logIndex: null,
          owner: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
          spender: {
            chainId: 1,
            coinType: 60,
            collection: {
              category: 'NFT_MARKETPLACE',
              description: 'Seaport conduit for handling approvals',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-opensea-f572b2cda3e44b404e6b67e7caedf2e71f24a387b37efa0fcfdf77a9359245b3.png',
              name: 'OpenSea',
              updatedAt: '2022-09-28T21:20:00',
            },
            contractAddress: '0x1e0049783f008a0085193e00003d00cd54003c71',
          },
          token: {
            chainId: 1,
            coinType: 60,
            collection: {
              category: 'NFT_MARKETPLACE',
              description:
                'Welcome to Candy! Shop our world-class NFTs here on OpenSea or at mlb.candy.com.\n\nCandy is Major League Baseball’s official NFT ecosystem where fans and collectors will be able to purchase, trade, and share officially licensed NFTs to deepen their love of the sport. Candy’s mission is to develop next-generation sports and culture digital assets, providing authentic objects and experiences that deepen fan engagement and connect people to their passions.',
              image:
                'https://i.seadn.io/gae/SwIlyjjg51ox7Xs6llIioZi2CixlNVFqa2ld23609CYLW7599HZxL3WQMJh0nLDQlYB20kISsEGhgz3MtcqB4uUHdkhB67buYOczAQ?w=500&auto=format',
              isEtherscanVerified: false,
              isOpenseaVerified: true,
              name: 'Candy Digital',
              openseaFees: {
                openseaFees: {
                  '0x0000a26b00c1f0df003000390027140000faa719': 250,
                },
                sellerFees: {
                  '0x329a0bb9f238a1e8bfad9a768924abcd4c1a1dbb': 1000,
                },
              },
              openseaSlug: 'candydigital',
              social: {
                discordUrl: 'https://discord.gg/6dVf3W8Z',
                externalUrl: 'https://www.candy.com/',
                instagramUsername: 'candydigital',
                mediumUsername: '',
                telegramUrl: '',
                twitterUsername: 'CandyDigital',
                wikiUrl: '',
              },
            },
            contractAddress: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
            decimals: 0,
            symbol: '',
          },
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
        },
        eventType: 'TOKEN_APPROVAL',
      },
      additionalEvents: [],
    },
    clientId: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
    consentStatus: 'INCOMPLETE',
    context: {
      chainId: 5,
    },
    id: 'eth-sign-warnings-domains-critical',
    kind: 'ETH_SEND_TRANSACTION',
    payload: {
      data: '0xa22cb4650000000000000000000000001e0049783f008a0085193e00003d00cd54003c710000000000000000000000000000000000000000000000000000000000000001',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      to: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
    },
    submitterId: 'b7bf5543-cdd1-470d-8ac1-0800cf066362',
    warnings: [
      {
        kind: 'DOMAIN',
        message: 'This site is flagged as malicious',
        severity: 'CRITICAL',
      },
    ],
  },
};

export const ethSignWarningsOpenSeaVerified = {
  clients: [
    {
      client_id: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
      client_name: 'Oauth app name',
      client_uri: 'app.com',
      logo_uri: 'app-logo.com',
      owner: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
      policy_uri: 'app-policy.com',
      tos_uri: 'app-tos.com',
    },
  ],
  transaction: {
    activity: {
      primaryEvent: {
        details: {
          allowance: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
          approvalType: 'APPROVAL_FOR_ALL',
          blockNumber: 0,
          blockTimestamp: '1970-01-01T00:00:00Z',
          logIndex: null,
          owner: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
          spender: {
            chainId: 1,
            coinType: 60,
            collection: {
              category: 'NFT_MARKETPLACE',
              description: 'Seaport conduit for handling approvals',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-opensea-f572b2cda3e44b404e6b67e7caedf2e71f24a387b37efa0fcfdf77a9359245b3.png',
              name: 'OpenSea',
              updatedAt: '2022-09-28T21:20:00',
            },
            contractAddress: '0x1e0049783f008a0085193e00003d00cd54003c71',
          },
          token: {
            chainId: 1,
            coinType: 60,
            collection: {
              category: 'NFT_MARKETPLACE',
              description:
                'Welcome to Candy! Shop our world-class NFTs here on OpenSea or at mlb.candy.com.\n\nCandy is Major League Baseball’s official NFT ecosystem where fans and collectors will be able to purchase, trade, and share officially licensed NFTs to deepen their love of the sport. Candy’s mission is to develop next-generation sports and culture digital assets, providing authentic objects and experiences that deepen fan engagement and connect people to their passions.',
              image:
                'https://i.seadn.io/gae/SwIlyjjg51ox7Xs6llIioZi2CixlNVFqa2ld23609CYLW7599HZxL3WQMJh0nLDQlYB20kISsEGhgz3MtcqB4uUHdkhB67buYOczAQ?w=500&auto=format',
              isEtherscanVerified: false,
              isOpenseaVerified: true,
              name: 'Candy Digital',
              openseaFees: {
                openseaFees: {
                  '0x0000a26b00c1f0df003000390027140000faa719': 250,
                },
                sellerFees: {
                  '0x329a0bb9f238a1e8bfad9a768924abcd4c1a1dbb': 1000,
                },
              },
              openseaSlug: 'candydigital',
              social: {
                discordUrl: 'https://discord.gg/6dVf3W8Z',
                externalUrl: 'https://www.candy.com/',
                instagramUsername: 'candydigital',
                mediumUsername: '',
                telegramUrl: '',
                twitterUsername: 'CandyDigital',
                wikiUrl: '',
              },
            },
            contractAddress: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
            decimals: 0,
            symbol: '',
          },
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
        },
        eventType: 'TOKEN_APPROVAL',
      },
      additionalEvents: [],
    },
    clientId: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
    consentStatus: 'INCOMPLETE',
    context: {
      chainId: 5,
    },
    contractInfo: {
      isContract: true,
      isOpenseaVerified: true,
      isEtherscanVerified: false,
      creationDate: '2022-11-09',
      recentTransactions: 0,
    },
    id: 'eth-sign-warnings-opensea-verified',
    kind: 'ETH_SEND_TRANSACTION',
    payload: {
      data: '0xa22cb4650000000000000000000000001e0049783f008a0085193e00003d00cd54003c710000000000000000000000000000000000000000000000000000000000000001',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      to: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
    },
    submitterId: 'b7bf5543-cdd1-470d-8ac1-0800cf066362',
    warnings: [
      {
        kind: 'DOMAIN',
        message: 'This site is flagged as malicious',
        severity: 'CRITICAL',
      },
    ],
  },
};

export const ethSignWarningsEtherscanVerified = {
  clients: [
    {
      client_id: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
      client_name: 'Oauth app name',
      client_uri: 'app.com',
      logo_uri: 'app-logo.com',
      owner: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
      policy_uri: 'app-policy.com',
      tos_uri: 'app-tos.com',
    },
  ],
  transaction: {
    activity: {
      primaryEvent: {
        details: {
          allowance: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
          approvalType: 'APPROVAL_FOR_ALL',
          blockNumber: 0,
          blockTimestamp: '1970-01-01T00:00:00Z',
          logIndex: null,
          owner: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
          spender: {
            chainId: 1,
            coinType: 60,
            collection: {
              category: 'NFT_MARKETPLACE',
              description: 'Seaport conduit for handling approvals',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-opensea-f572b2cda3e44b404e6b67e7caedf2e71f24a387b37efa0fcfdf77a9359245b3.png',
              name: 'OpenSea',
              updatedAt: '2022-09-28T21:20:00',
            },
            contractAddress: '0x1e0049783f008a0085193e00003d00cd54003c71',
          },
          token: {
            chainId: 1,
            coinType: 60,
            collection: {
              category: 'NFT_MARKETPLACE',
              description:
                'Welcome to Candy! Shop our world-class NFTs here on OpenSea or at mlb.candy.com.\n\nCandy is Major League Baseball’s official NFT ecosystem where fans and collectors will be able to purchase, trade, and share officially licensed NFTs to deepen their love of the sport. Candy’s mission is to develop next-generation sports and culture digital assets, providing authentic objects and experiences that deepen fan engagement and connect people to their passions.',
              image:
                'https://i.seadn.io/gae/SwIlyjjg51ox7Xs6llIioZi2CixlNVFqa2ld23609CYLW7599HZxL3WQMJh0nLDQlYB20kISsEGhgz3MtcqB4uUHdkhB67buYOczAQ?w=500&auto=format',
              isEtherscanVerified: false,
              isOpenseaVerified: true,
              name: 'Candy Digital',
              openseaFees: {
                openseaFees: {
                  '0x0000a26b00c1f0df003000390027140000faa719': 250,
                },
                sellerFees: {
                  '0x329a0bb9f238a1e8bfad9a768924abcd4c1a1dbb': 1000,
                },
              },
              openseaSlug: 'candydigital',
              social: {
                discordUrl: 'https://discord.gg/6dVf3W8Z',
                externalUrl: 'https://www.candy.com/',
                instagramUsername: 'candydigital',
                mediumUsername: '',
                telegramUrl: '',
                twitterUsername: 'CandyDigital',
                wikiUrl: '',
              },
            },
            contractAddress: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
            decimals: 0,
            symbol: '',
          },
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
        },
        eventType: 'TOKEN_APPROVAL',
      },
      additionalEvents: [],
    },
    clientId: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
    consentStatus: 'INCOMPLETE',
    context: {
      chainId: 5,
    },
    contractInfo: {
      isContract: true,
      isOpenseaVerified: false,
      isEtherscanVerified: true,
      creationDate: '2022-11-09',
      recentTransactions: 0,
    },
    id: 'eth-sign-warnings-etherscan-verified',
    kind: 'ETH_SEND_TRANSACTION',
    payload: {
      data: '0xa22cb4650000000000000000000000001e0049783f008a0085193e00003d00cd54003c710000000000000000000000000000000000000000000000000000000000000001',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      to: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
    },
    submitterId: 'b7bf5543-cdd1-470d-8ac1-0800cf066362',
    warnings: [
      {
        kind: 'DOMAIN',
        message: 'This site is flagged as malicious',
        severity: 'CRITICAL',
      },
    ],
  },
};

export const ethSignWarningsAll = {
  clients: [
    {
      client_id: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
      client_name: 'Oauth app name',
      client_uri: 'app.com',
      logo_uri: 'app-logo.com',
      owner: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
      policy_uri: 'app-policy.com',
      tos_uri: 'app-tos.com',
    },
  ],
  transaction: {
    activity: {
      primaryEvent: {
        eventType: 'TOKEN_TRANSFER',
        details: {
          from: '0x75448F078C57dfEEa0A4ee75D59a329c796e60cC',
          to: '0x749c28abcaa80c194aa985d5662c370e608aa0fa',
          kind: 'NFT',
          token: {
            coinType: 60,
            chainId: 1,
            contractAddress: '0xb75f09b4340aeb85cd5f2dd87d31751edc11ed39',
            collection: {
              updatedAt: '2022-10-11T06:12:30.804340594',
              description:
                'Welcome to Candy! Shop our world-class NFTs here on OpenSea or at mlb.candy.com.\n\nCandy is Major League Baseball’s official NFT ecosystem where fans and collectors will be able to purchase, trade, and share officially licensed NFTs to deepen their love of the sport. Candy’s mission is to develop next-generation sports and culture digital assets, providing authentic objects and experiences that deepen fan engagement and connect people to their passions.',
              name: 'Candy Digital',
              image:
                'https://i.seadn.io/gae/SwIlyjjg51ox7Xs6llIioZi2CixlNVFqa2ld23609CYLW7599HZxL3WQMJh0nLDQlYB20kISsEGhgz3MtcqB4uUHdkhB67buYOczAQ?w=500&auto=format',
              openseaSlug: 'candydigital',
              isOpenseaVerified: true,
              isEtherscanVerified: false,
              social: {
                telegramUrl: null,
                twitterUsername: null,
                instagramUsername: null,
                wikiUrl: null,
                mediumUsername: null,
                discordUrl: null,
                externalUrl: null,
              },
              openseaFees: {
                sellerFees: {
                  '0x329a0bb9f238a1e8bfad9a768924abcd4c1a1dbb': 1000,
                },
                openseaFees: {
                  '0x0000a26b00c1f0df003000390027140000faa719': 250,
                },
              },
            },
            id: '0x1815',
            metadata: {
              name: 'Wrigley Field Steel Edition #14/346',
              image:
                'https://cdn.bitskistatic.com/tokens-raw/90419700-e0fd-4e06-8901-8b73de32bcde/82a7f728-8e1a-4b29-af06-c6e84cc7ac6c.jpg',
              animationUrl:
                'https://cdn.bitskistatic.com/tokens-raw/90419700-e0fd-4e06-8901-8b73de32bcde/a7aff753-acf3-467f-ac63-fe14059a41da.mp4',
              description:
                'This open edition MLB Stadium Series NFT collectible features artwork by renowned digital artist S. Preston and pays homage to Wrigley Field’s iconic Ivy Wall. Wrigley Field opened in 1914 and is home to the Chicago Cubs.\n\nNFT Terms: https://bit.ly/3jH6djl',
              externalUrl: 'https://www.bitski.com/Candy',
              marketplaceUrl:
                'https://opensea.io/assets/ethereum/0x7a44228e46cdd37a85c0b07c4043b86c11e8c315/185768422266454438206668338187825610214',
            },
          },
          amount: '10',
          blockNumber: 0,
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
          logIndex: null,
        },
      },
      additionalEvents: [],
    },
    clientId: '33a8aa19-0d83-4f0d-888d-8dfca46a0eac',
    consentStatus: 'INCOMPLETE',
    context: {
      chainId: 5,
    },
    contractInfo: {
      isContract: true,
      isOpenseaVerified: true,
      isEtherscanVerified: true,
      creationDate: '2022-11-09',
      recentTransactions: 0,
    },
    id: 'eth-sign-warnings-all',
    kind: 'ETH_SEND_TRANSACTION',
    payload: {
      data: '0x23b872dd0000000000000000000000000efb9c52b73d780ee93fdc5d8b0bd4af38842ee3000000000000000000000000000000000000000000000000000000000000dead000000000000000000000000000000000000000000000000000000000000086c',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      gas: '0xaccf',
      gasPrice: '0x470327706',
      nonce: '0x1c',
      to: '0xd17fce1c4c199ff9d02c9674abf52fd35d9d058c',
    },
    submitterId: 'b7bf5543-cdd1-470d-8ac1-0800cf066362',
    warnings: [
      {
        kind: 'DOMAIN',
        message: 'This site is flagged as malicious',
        severity: 'CRITICAL',
      },
      {
        kind: 'TRANSACTION',
        message: 'You are allowing this website to withdraw funds from your account in the future',
        severity: 'CRITICAL',
      },
      {
        kind: 'TRANSACTION',
        message: 'You are allowing this website to withdraw funds from your account in the future',
        severity: 'WARNING',
      },
      {
        kind: 'DOMAIN',
        message: 'This site is flagged as malicious',
        severity: 'WARNING',
      },
    ],
  },
};
