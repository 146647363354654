export const ethSignTypedDataOpensea = {
  clients: [
    {
      client_id: '5bc0d0c2-cd6a-44a6-886b-f3f111008596',
      client_name: 'Native Client API',
      client_uri: '',
      logo_uri: '',
      owner: '',
      policy_uri: '',
      tos_uri: '',
    },
  ],
  transaction: {
    activity: { additionalEvents: [], primaryEvent: { eventType: 'UNKNOWN' } },
    clientId: '5bc0d0c2-cd6a-44a6-886b-f3f111008596',
    consentStatus: 'INCOMPLETE',
    context: {
      chainId: 1,
      imageUrl: 'https://opensea.io/static/images/favicon/32x32.png',
      origin: 'https://opensea.io',
    },
    contractInfo: { isContract: false, isEtherscanVerified: false },
    id: 'eth-sign-typed-data-opensea',
    kind: 'ETH_SIGN',
    payload: {
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      message:
        '0x57656c636f6d6520746f204f70656e536561210a0a436c69636b20746f207369676e20696e20616e642061636365707420746865204f70656e536561205465726d73206f6620536572766963653a2068747470733a2f2f6f70656e7365612e696f2f746f730a0a5468697320726571756573742077696c6c206e6f742074726967676572206120626c6f636b636861696e207472616e73616374696f6e206f7220636f737420616e792067617320666565732e0a0a596f75722061757468656e7469636174696f6e207374617475732077696c6c20726573657420616674657220323420686f7572732e0a0a57616c6c657420616464726573733a0a3078376363376333393866636162666163643230366137336136333439346139636435306532363639370a0a4e6f6e63653a0a32646631363432662d343833642d343665652d386231642d333764373638623432633061',
    },
    submitterId: '74678520-7d89-493d-b22f-bad9c314b2cf',
    warnings: [],
  },
};

export const ethSignTransactionOpenseaPurchase = {
  transaction: {
    id: 'eth-sign-transaction-opensea-purchase',
    kind: 'ETH_SIGN_TRANSACTION',
    consentStatus: 'INCOMPLETE',
    payload: {
      to: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      value: '0x0',
      data: '0xfb0f3ee10000000000000000000000000000000000000000000000000000000000000020000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000014fdbfdba7c80000000000000000000000000036f6d051aa0e2a9f1942116cc52e0c0362486fea000000000000000000000000004c00500000ad104d7dbd00e3ae0a5c00560c0000000000000000000000000069eda943891e3730a56125a7e0340cb109b5532f000000000000000000000000000000000000000000000000000000000000107b0000000000000000000000000000000000000000000000000000000000000001000000000000000000000000000000000000000000000000000000000000000300000000000000000000000000000000000000000000000000000000632a3d4c00000000000000000000000000000000000000000000000000000000632b8ecc3000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000008168f208f0ecc29479ceda6b5b6771b20000007b02230091a7ed01230072f7006a004d60a8d4e71d599b8104250f00000000007b02230091a7ed01230072f7006a004d60a8d4e71d599b8104250f00000000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000000000000000024000000000000000000000000000000000000000000000000000000000000002e00000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000000093cafac6a8000000000000000000000000000000a26b00c1f0df003000390027140000faa7190000000000000000000000000000000000000000000000000001862c589bd000000000000000000000000000d578e4af4ad83e52e65e5f7b72e2113b43f8211800000000000000000000000000000000000000000000000000000000000000400618143ec07436fa2292d7aa46e563aa780ed74347572b2d00d6ea9701171729ba9f63ac829041c7e8324c1d1fc310f0a047a113d7ae696bbfac91ffa5a7d8fc',
      gas: '0xd228',
      gasPrice: '0x2ca573f2c',
    },
    context: {
      chainId: 5,
    },
    activity: {
      primaryEvent: {
        eventType: 'TOKEN_SALE',
        details: {
          seller: '0xbdd95abe8a7694ccd77143376b0fbea183e6a740',
          buyer: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
          token: {
            coinType: 60,
            chainId: 1,
            contractAddress: '0x35f3b8f37e9341f289398b70fa2c576dd102df75',
            collection: {
              description: 'We all are BALUBA',
              name: 'BALUBA',
              image:
                'https://i.seadn.io/gcs/files/eb3dad6445ae0541e5314bbef1aa7965.png?w=500&auto=format',
              isOpenseaVerified: false,
              isEtherscanVerified: false,
              openseaFees: {
                sellerFees: { '0xa56efca36c2d2b4d4d2ac13575ecc785dc90fbe9': 750 },
                openseaFees: { '0x0000a26b00c1f0df003000390027140000faa719': 250 },
              },
            },
            id: '0x245a',
            metadata: {
              name: 'BALUBA #9306',
              image:
                'https://i.seadn.io/gae/__c8lBGtmTgZMWvnJUTdxbwuMNEXX5EA8qIrIufVuYhSD2MN5Oor0a9iP7NVGjKD41KOUOiHtQ7dkeb_8BbSHpgWfvdkOWrShgVoKg?auto=format&w=1000',
              description:
                'BALUBA is an abstract NFT issued a total of 10,000 copies, each one is a unique work',
              marketplaceUrl:
                'https://opensea.io/assets/ethereum/0x35f3b8f37e9341f289398b70fa2c576dd102df75/9306',
              attributes: [
                {
                  traitType: 'Background',
                  value: 'GREEN',
                },
                {
                  traitType: 'BODY',
                  value: 'FLOWER',
                },
                {
                  traitType: 'HEAD',
                  value: 'SHERLOCK HOLMES',
                },
              ],
            },
            symbol: 'BALUBA',
          },
          amount: '1',
          payment: {
            from: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
            to: '0x00000000006c3852cbef3e08e8df289169ede581',
            kind: 'NATIVE',
            token: {
              coinType: 60,
              chainId: 1,
              contractAddress: '0x0000000000000000000000000000000000000000',
            },
            amount: '800000000000000',
            blockNumber: 0,
            transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
            logIndex: null,
          },
          purchaseType: 'BUY_NOW',
          platform: {
            contractAddress: '0x00000000006c3852cbef3e08e8df289169ede581',
            chainId: 1,
            coinType: 60,
            name: 'OpenSea',
            collection: {
              description: 'Seaport marketplace for fulfilling orders',
              name: 'OpenSea',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-opensea-f572b2cda3e44b404e6b67e7caedf2e71f24a387b37efa0fcfdf77a9359245b3.png',
              category: 'NFT_MARKETPLACE',
            },
          },
          blockNumber: 0,
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
          logIndex: null,
          blockTimestamp: '1970-01-01T00:00:00Z',
        },
      },
      additionalEvents: [],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};

export const ethSignTransactionOpenseaList = {
  transaction: {
    id: 'eth-sign-typed-data-opensea-list',
    kind: 'ETH_SIGN_TYPED_DATA',
    consentStatus: 'INCOMPLETE',
    payload: {
      domain: {
        chainId: '0x1',
        name: 'Seaport',
        verifyingContract: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
        version: '1.1',
      },
      message: {
        conduitKey: '0x0000007b02230091a7ed01230072f7006a004d60a8d4e71d599b8104250f0000',
        consideration: [
          {
            endAmount: '0x18493fba64ef0000',
            identifierOrCriteria: '0',
            itemType: '0',
            recipient: '0x0eFB9c52B73D780EE93fDc5D8b0BD4AF38842EE3',
            startAmount: '0x18493fba64ef0000',
            token: '0x0000000000000000000000000000000000000000',
          },
          {
            endAmount: '0xb1a2bc2ec50000',
            identifierOrCriteria: '0',
            itemType: '0',
            recipient: '0x0000a26b00c1F0DF003000390027140000fAa719',
            startAmount: '0xb1a2bc2ec50000',
            token: '0x0000000000000000000000000000000000000000',
          },
          {
            endAmount: '0x2c68af0bb140000',
            identifierOrCriteria: '0',
            itemType: '0',
            recipient: '0x329a0BB9f238a1E8BfAd9A768924ABCD4C1A1DBB',
            startAmount: '0x2c68af0bb140000',
            token: '0x0000000000000000000000000000000000000000',
          },
        ],
        counter: '0',
        endTime: '1662591708',
        offer: [
          {
            endAmount: '0x1',
            identifierOrCriteria: '0x8bc1b25c026f49b39504ac9021647de6',
            itemType: '3',
            startAmount: '0x1',
            token: '0x7A44228E46cdd37a85C0B07c4043b86c11e8c315',
          },
        ],
        offerer: '0x0eFB9c52B73D780EE93fDc5D8b0BD4AF38842EE3',
        orderType: '3',
        salt: '0xbbc1c7e16b99b7',
        startTime: '1662505308',
        totalOriginalConsiderationItems: '3',
        zone: '0x004C00500000aD104D7DBd00e3ae0A5C00560C00',
        zoneHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
      },
      primaryType: 'OrderComponents',
      types: {
        ConsiderationItem: [
          { name: 'itemType', type: 'uint8' },
          { name: 'token', type: 'address' },
          { name: 'identifierOrCriteria', type: 'uint256' },
          { name: 'startAmount', type: 'uint256' },
          { name: 'endAmount', type: 'uint256' },
          { name: 'recipient', type: 'address' },
        ],
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' },
        ],
        OfferItem: [
          { name: 'itemType', type: 'uint8' },
          { name: 'token', type: 'address' },
          { name: 'identifierOrCriteria', type: 'uint256' },
          { name: 'startAmount', type: 'uint256' },
          { name: 'endAmount', type: 'uint256' },
        ],
        OrderComponents: [
          { name: 'offerer', type: 'address' },
          { name: 'zone', type: 'address' },
          { name: 'offer', type: 'OfferItem[]' },
          { name: 'consideration', type: 'ConsiderationItem[]' },
          { name: 'orderType', type: 'uint8' },
          { name: 'startTime', type: 'uint256' },
          { name: 'endTime', type: 'uint256' },
          { name: 'zoneHash', type: 'bytes32' },
          { name: 'salt', type: 'uint256' },
          { name: 'conduitKey', type: 'bytes32' },
          { name: 'counter', type: 'uint256' },
        ],
      },
    },
    context: {
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
    },
    activity: {
      primaryEvent: {
        eventType: 'TOKEN_ORDER',
        details: {
          orderType: 'LISTING',
          token: {
            coinType: 60,
            chainId: 1,
            contractAddress: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
            collection: {
              description:
                'Welcome to Candy! Shop our world-class NFTs here on OpenSea or at mlb.candy.com.\n\nCandy is Major League Baseball’s official NFT ecosystem where fans and collectors will be able to purchase, trade, and share officially licensed NFTs to deepen their love of the sport. Candy’s mission is to develop next-generation sports and culture digital assets, providing authentic objects and experiences that deepen fan engagement and connect people to their passions.',
              name: 'Candy Digital',
              image:
                'https://i.seadn.io/gae/SwIlyjjg51ox7Xs6llIioZi2CixlNVFqa2ld23609CYLW7599HZxL3WQMJh0nLDQlYB20kISsEGhgz3MtcqB4uUHdkhB67buYOczAQ?w=500&auto=format',
              isOpenseaVerified: true,
              isEtherscanVerified: false,
              openseaFees: {
                sellerFees: { '0x329a0bb9f238a1e8bfad9a768924abcd4c1a1dbb': 1000 },
                openseaFees: { '0x0000a26b00c1f0df003000390027140000faa719': 250 },
              },
            },
            id: '0x8bc1b25c026f49b39504ac9021647de6',
            metadata: {
              name: 'Wrigley Field Steel Edition #14/346',
              image:
                'https://cdn.bitskistatic.com/tokens-raw/90419700-e0fd-4e06-8901-8b73de32bcde/82a7f728-8e1a-4b29-af06-c6e84cc7ac6c.jpg',
              animationUrl:
                'https://cdn.bitskistatic.com/tokens-raw/90419700-e0fd-4e06-8901-8b73de32bcde/a7aff753-acf3-467f-ac63-fe14059a41da.mp4',
              description:
                'This open edition MLB Stadium Series NFT collectible features artwork by renowned digital artist S. Preston and pays homage to Wrigley Field’s iconic Ivy Wall. Wrigley Field opened in 1914 and is home to the Chicago Cubs.\n\nNFT Terms: https://bit.ly/3jH6djl',
              externalUrl: 'https://www.bitski.com/Candy',
              marketplaceUrl:
                'https://opensea.io/assets/ethereum/0x7a44228e46cdd37a85c0b07c4043b86c11e8c315/185768422266454438206668338187825610214',
              attributes: [],
            },
          },
          basePrice: '0x1BC16D674EC80000',
          endPrice: '0x1BC16D674EC80000',
          createdDate: '2022-09-07T13:43:36Z',
          expirationDate: '2022-10-01T05:18:00Z',
          listingType: 'FIXED_PRICE',
          maker: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
          orderHash: null,
          paymentToken: {
            coinType: 60,
            chainId: 1,
            contractAddress: '0x0000000000000000000000000000000000000000',
            id: '0x0',
            symbol: 'ETH',
          },
          platformFee: null,
          creatorFee: {
            amountRaw: '0xc52cf4b908c000',
            rate: 555,
            recipient: '0x329a0bb9f238a1e8bfad9a768924abcd4c1a1dbb',
          },
          quantity: 1,
          taker: null,
        },
      },
      additionalEvents: [],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};

export const ethSignTransactionOpenseaOffer = {
  transaction: {
    id: 'eth-sign-typed-data-opensea-offer',
    kind: 'ETH_SIGN_TYPED_DATA',
    consentStatus: 'INCOMPLETE',
    payload: {
      domain: {
        chainId: '0x1',
        name: 'Seaport',
        verifyingContract: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
        version: '1.1',
      },
      message: {
        conduitKey: '0x0000007b02230091a7ed01230072f7006a004d60a8d4e71d599b8104250f0000',
        consideration: [
          {
            endAmount: '0x1',
            identifierOrCriteria: '0x2227',
            itemType: '0x2',
            recipient: '0x0eFB9c52B73D780EE93fDc5D8b0BD4AF38842EE3',
            startAmount: '0x1',
            token: '0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e',
          },
          {
            endAmount: '0x470de4df82000',
            identifierOrCriteria: '0x0',
            itemType: '0x1',
            recipient: '0x0000a26b00c1F0DF003000390027140000fAa719',
            startAmount: '0x470de4df82000',
            token: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
          },
          {
            endAmount: '0x8e1bc9bf04000',
            identifierOrCriteria: '0x0',
            itemType: '0x1',
            recipient: '0xd1F124cc900624e1ff2d923180b3924147364380',
            startAmount: '0x8e1bc9bf04000',
            token: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
          },
        ],
        counter: '0x0',
        endTime: '0x6318fc09',
        offer: [
          {
            endAmount: '0xb1a2bc2ec50000',
            identifierOrCriteria: '0x0',
            itemType: '0x1',
            startAmount: '0xb1a2bc2ec50000',
            token: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
          },
        ],
        offerer: '0x0eFB9c52B73D780EE93fDc5D8b0BD4AF38842EE3',
        orderType: '0x2',
        salt: '0xbde9ef734a7d97',
        startTime: '0x6317c44f',
        totalOriginalConsiderationItems: '3',
        zone: '0x004C00500000aD104D7DBd00e3ae0A5C00560C00',
        zoneHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
      },
      primaryType: 'OrderComponents',
      types: {
        ConsiderationItem: [
          { name: 'itemType', type: 'uint8' },
          { name: 'token', type: 'address' },
          { name: 'identifierOrCriteria', type: 'uint256' },
          { name: 'startAmount', type: 'uint256' },
          { name: 'endAmount', type: 'uint256' },
          { name: 'recipient', type: 'address' },
        ],
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' },
        ],
        OfferItem: [
          { name: 'itemType', type: 'uint8' },
          { name: 'token', type: 'address' },
          { name: 'identifierOrCriteria', type: 'uint256' },
          { name: 'startAmount', type: 'uint256' },
          { name: 'endAmount', type: 'uint256' },
        ],
        OrderComponents: [
          { name: 'offerer', type: 'address' },
          { name: 'zone', type: 'address' },
          { name: 'offer', type: 'OfferItem[]' },
          { name: 'consideration', type: 'ConsiderationItem[]' },
          { name: 'orderType', type: 'uint8' },
          { name: 'startTime', type: 'uint256' },
          { name: 'endTime', type: 'uint256' },
          { name: 'zoneHash', type: 'bytes32' },
          { name: 'salt', type: 'uint256' },
          { name: 'conduitKey', type: 'bytes32' },
          { name: 'counter', type: 'uint256' },
        ],
      },
    },
    context: {
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
    },
    activity: {
      primaryEvent: {
        eventType: 'TOKEN_ORDER',
        details: {
          token: {
            coinType: 60,
            chainId: 1,
            contractAddress: '0x8a90cab2b38dba80c64b7734e58ee1db38b8992e',
            collection: {
              description:
                'A community-driven collectibles project featuring art by Burnt Toast. Doodles come in a joyful range of colors, traits and sizes with a collection size of 10,000. Each Doodle allows its owner to vote for experiences and activations paid for by the Doodles Community Treasury.',
              name: 'Doodles',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-doodles-outline-1ffb2fa6cea2140978f035d3a00b3b17.png',
              isOpenseaVerified: true,
              isEtherscanVerified: false,
              openseaFees: {
                sellerFees: { '0xd1f124cc900624e1ff2d923180b3924147364380': 500 },
                openseaFees: { '0x0000a26b00c1f0df003000390027140000faa719': 250 },
              },
            },
            id: '0x2227',
            metadata: {
              name: 'Doodle #8743',
              image:
                'https://bitski.infura-ipfs.io/ipfs/QmctKxxoLWcRuiKrpHMFLuAwiePW9w4DZdDfBqs9vNxTxg',
              description:
                'A community-driven collectibles project featuring art by Burnt Toast. Doodles come in a joyful range of colors, traits and sizes with a collection size of 10,000. Each Doodle allows its owner to vote for experiences and activations paid for by the Doodles Community Treasury. Burnt Toast is the working alias for Scott Martin, a Canadian–based illustrator, designer, animator and muralist.',
              marketplaceUrl:
                'https://opensea.io/assets/ethereum/0x8a90cab2b38dba80c64b7734e58ee1db38b8992e/8743',
              attributes: [],
            },
            symbol: 'DOODLE',
          },
          basePrice: '0xb1a2bc2ec50000',
          endPrice: '0xb1a2bc2ec50000',
          createdDate: '2022-09-06T22:06:07Z',
          expirationDate: '2022-10-02T20:16:09Z',
          listingType: null,
          maker: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
          orderHash: null,
          orderType: 'OFFER',
          paymentToken: {
            coinType: 60,
            chainId: 1,
            contractAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
            symbol: 'WETH',
            collection: {
              name: 'WETH',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-ethereum-1508bede72698750836584012a0062eff03ca341705b5eef8905826b4fee1d71.png',
            },
            id: '0x0',
          },
          quantity: 50000000000000000,
          taker: null,
        },
      },
      additionalEvents: [],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};

export const ethSignTransactionOpenseaPurchaseInsufficient = {
  transaction: {
    id: 'eth-sign-transaction-opensea-purchase-insufficient',
    kind: 'ETH_SIGN_TRANSACTION',
    consentStatus: 'INCOMPLETE',
    payload: {
      to: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      value: '0x0',
      data: '0xfb0f3ee10000000000000000000000000000000000000000000000000000000000000020000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000014fdbfdba7c80000000000000000000000000036f6d051aa0e2a9f1942116cc52e0c0362486fea000000000000000000000000004c00500000ad104d7dbd00e3ae0a5c00560c0000000000000000000000000069eda943891e3730a56125a7e0340cb109b5532f000000000000000000000000000000000000000000000000000000000000107b0000000000000000000000000000000000000000000000000000000000000001000000000000000000000000000000000000000000000000000000000000000300000000000000000000000000000000000000000000000000000000632a3d4c00000000000000000000000000000000000000000000000000000000632b8ecc3000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000008168f208f0ecc29479ceda6b5b6771b20000007b02230091a7ed01230072f7006a004d60a8d4e71d599b8104250f00000000007b02230091a7ed01230072f7006a004d60a8d4e71d599b8104250f00000000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000000000000000024000000000000000000000000000000000000000000000000000000000000002e00000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000000093cafac6a8000000000000000000000000000000a26b00c1f0df003000390027140000faa7190000000000000000000000000000000000000000000000000001862c589bd000000000000000000000000000d578e4af4ad83e52e65e5f7b72e2113b43f8211800000000000000000000000000000000000000000000000000000000000000400618143ec07436fa2292d7aa46e563aa780ed74347572b2d00d6ea9701171729ba9f63ac829041c7e8324c1d1fc310f0a047a113d7ae696bbfac91ffa5a7d8fc',
      gas: '0x334EC40',
      gasPrice: '0x2ca573f2c',
    },
    context: {
      chainId: 5,
    },
    activity: {
      primaryEvent: {
        eventType: 'TOKEN_SALE',
        details: {
          seller: '0xbdd95abe8a7694ccd77143376b0fbea183e6a740',
          buyer: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
          token: {
            coinType: 60,
            chainId: 1,
            contractAddress: '0x35f3b8f37e9341f289398b70fa2c576dd102df75',
            collection: {
              description: 'We all are BALUBA',
              name: 'BALUBA',
              image:
                'https://i.seadn.io/gcs/files/eb3dad6445ae0541e5314bbef1aa7965.png?w=500&auto=format',
              isOpenseaVerified: false,
              isEtherscanVerified: false,
              openseaFees: {
                sellerFees: { '0xa56efca36c2d2b4d4d2ac13575ecc785dc90fbe9': 750 },
                openseaFees: { '0x0000a26b00c1f0df003000390027140000faa719': 250 },
              },
            },
            id: '0x245a',
            metadata: {
              name: 'BALUBA #9306',
              image:
                'https://i.seadn.io/gae/__c8lBGtmTgZMWvnJUTdxbwuMNEXX5EA8qIrIufVuYhSD2MN5Oor0a9iP7NVGjKD41KOUOiHtQ7dkeb_8BbSHpgWfvdkOWrShgVoKg?auto=format&w=1000',
              description:
                'BALUBA is an abstract NFT issued a total of 10,000 copies, each one is a unique work',
              marketplaceUrl:
                'https://opensea.io/assets/ethereum/0x35f3b8f37e9341f289398b70fa2c576dd102df75/9306',
              attributes: [
                {
                  traitType: 'Background',
                  value: 'GREEN',
                },
                {
                  traitType: 'BODY',
                  value: 'FLOWER',
                },
                {
                  traitType: 'HEAD',
                  value: 'SHERLOCK HOLMES',
                },
              ],
            },
            symbol: 'BALUBA',
          },
          amount: '1',
          payment: {
            from: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
            to: '0x00000000006c3852cbef3e08e8df289169ede581',
            kind: 'TOKEN',
            token: {
              coinType: 60,
              chainId: 1,
              contractAddress: '0x0000000000000000000000000000000000000000',
              collection: {},
            },
            amount: '800000000000000',
            blockNumber: 0,
            transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
            logIndex: null,
          },
          purchaseType: 'BUY_NOW',
          platform: {
            address: '0x00000000006c3852cbef3e08e8df289169ede581',
            chainId: 1,
            coinType: 60,
            name: 'OpenSea',
            metadata: {
              description: 'Seaport marketplace for fulfilling orders',
              name: 'OpenSea',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-opensea-f572b2cda3e44b404e6b67e7caedf2e71f24a387b37efa0fcfdf77a9359245b3.png',
              category: 'NFT_MARKETPLACE',
            },
          },
          blockNumber: 0,
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
          logIndex: null,
          blockTimestamp: '1970-01-01T00:00:00Z',
        },
      },
      additionalEvents: [],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};

export const ethSignTransactionOpenseaAcceptOffer = {
  transaction: {
    id: 'eth-sign-transaction-opensea-accept-offer',
    kind: 'ETH_SIGN_TRANSACTION',
    consentStatus: 'INCOMPLETE',
    payload: {
      to: '0x7a44228e46cdd37a85c0b07c4043b86c11e8c315',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      value: '0x0',
      data: '0xfb0f3ee10000000000000000000000000000000000000000000000000000000000000020000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000014fdbfdba7c80000000000000000000000000036f6d051aa0e2a9f1942116cc52e0c0362486fea000000000000000000000000004c00500000ad104d7dbd00e3ae0a5c00560c0000000000000000000000000069eda943891e3730a56125a7e0340cb109b5532f000000000000000000000000000000000000000000000000000000000000107b0000000000000000000000000000000000000000000000000000000000000001000000000000000000000000000000000000000000000000000000000000000300000000000000000000000000000000000000000000000000000000632a3d4c00000000000000000000000000000000000000000000000000000000632b8ecc3000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000008168f208f0ecc29479ceda6b5b6771b20000007b02230091a7ed01230072f7006a004d60a8d4e71d599b8104250f00000000007b02230091a7ed01230072f7006a004d60a8d4e71d599b8104250f00000000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000000000000000024000000000000000000000000000000000000000000000000000000000000002e00000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000000093cafac6a8000000000000000000000000000000a26b00c1f0df003000390027140000faa7190000000000000000000000000000000000000000000000000001862c589bd000000000000000000000000000d578e4af4ad83e52e65e5f7b72e2113b43f8211800000000000000000000000000000000000000000000000000000000000000400618143ec07436fa2292d7aa46e563aa780ed74347572b2d00d6ea9701171729ba9f63ac829041c7e8324c1d1fc310f0a047a113d7ae696bbfac91ffa5a7d8fc',
      gas: '0x2b43e',
      gasPrice: '0x2ca573f2c',
    },
    context: {
      chainId: 5,
    },
    activity: {
      primaryEvent: {
        details: {
          amount: '1',
          blockNumber: 0,
          blockTimestamp: '1970-01-01T00:00:00Z',
          buyer: '0xefffdc05e7c5b305fbd504366b01f2d6424cb8c4',
          creatorFee: {
            amountRaw: '0x6379da05b60000',
            rate: 70,
            recipient: '0xd74c4760b2cdb4fbc74dfcca370cad5ec27a7db9',
          },
          logIndex: null,
          payment: {
            amount: '147375000000000000',
            blockNumber: 0,
            from: '0x68aa0272da59f6292ae322f2c6e86dfbc18a6068',
            kind: 'NATIVE',
            logIndex: null,
            to: '0xefffdc05e7c5b305fbd504366b01f2d6424cb8c4',
            token: {
              chainId: 1,
              coinType: 60,
              contractAddress: '0x0000000000000000000000000000000000000000',
            },
            transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
          },
          platform: {
            chainId: 1,
            coinType: 60,
            collection: {
              category: 'NFT_MARKETPLACE',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-opensea-square-7b2d75b345a4587c3329b610799d76a6e64d35d98791f4374214a8f7334dfea0.png',
              name: 'OpenSea',
              updatedAt: '2022-12-02T16:53:20',
            },
            contractAddress: '0x00000000006c3852cbef3e08e8df289169ede581',
            name: 'OpenSea',
          },
          platformFee: {
            amountRaw: '0x0',
            rate: 0,
            recipient: '0x0000000000000000000000000000000000000000',
          },
          purchaseType: 'ACCEPT_OFFER',
          seller: '0x68aa0272da59f6292ae322f2c6e86dfbc18a6068',
          token: {
            chainId: 1,
            coinType: 60,
            collection: {
              description: '',
              image:
                'https://i.seadn.io/gcs/files/860c139ac3d3ce30b93b7a8363c8b3f8.gif?w=500&auto=format',
              isOpenseaVerified: false,
              name: 'Lascaux Editions',
              openseaFees: {
                openseaFees: {
                  '0x0000a26b00c1f0df003000390027140000faa719': 250,
                },
                sellerFees: {
                  '0x7caa2d25de293e2329795a7c9990fc39ac35f3e3': 1000,
                },
              },
            },
            contractAddress: '0xf36c296e87dd2d7adcef251a542561d4bbe07714',
            id: '0x1',
            metadata: {
              description: 'Are we really this fukt? Find out Christmas Day',
              image: 'https://arweave.net/Z71cowsU2Wh1FXydKgJTBuNqXuW_jpXBl-HoliJ_NZU',
              marketplaceUrl:
                'https://opensea.io/assets/ethereum/0xf36c296e87dd2d7adcef251a542561d4bbe07714/1',
              name: 'Fukt',
            },
            symbol: 'MXFK',
          },
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
        },
        eventType: 'TOKEN_SALE',
      },
      additionalEvents: [],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};
