// This is an ERC721 transfer that gets returned from the API as an ERC20.
// Unfortunately this is possible since their transfer functions share the same
// signature, so the backend needs to fetch the token standard (which can fail).
// If this happens, the 721 transfer will be decoded as a 20. The main differences
// are the `details.kind` is "TOKEN" instead of "NFT" and `details.amount` is
// actually the `details.token.id` (while amount should be 1).
export const ethSignTransactionTransferERC721Incorrect = {
  transaction: {
    id: 'eth-sign-transaction-transfer-erc721-incorrect',
    payload: {
      data: '0x23b872dd0000000000000000000000000efb9c52b73d780ee93fdc5d8b0bd4af38842ee3000000000000000000000000000000000000000000000000000000000000dead000000000000000000000000000000000000000000000000000000000000086c',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      gas: '0xaccf',
      gasPrice: '0x470327706',
      nonce: '0x1c',
      to: '0xd17fce1c4c199ff9d02c9674abf52fd35d9d058c',
    },
    activity: {
      primaryEvent: {
        details: {
          amount: '2156',
          blockNumber: 0,
          from: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
          kind: 'TOKEN',
          logIndex: null,
          to: '0x000000000000000000000000000000000000dead',
          token: {
            chainId: 1,
            coinType: 60,
            contractAddress: '0xd17fce1c4c199ff9d02c9674abf52fd35d9d058c',
          },
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
        },
        eventType: 'TOKEN_TRANSFER',
      },
      additionalEvents: [],
    },
    clientId: '034bfea4-af92-4165-a9ca-fb3c7e39193d',
    context: { chainId: 1, currentBalance: '0x83ae067470952' },
    kind: 'ETH_SIGN_TRANSACTION',
    consentStatus: 'INCOMPLETE',
    submitterId: 'e7940a52-b551-4965-82f3-8a2de6f50cdb',
  },
  clients: [
    {
      client_id: '034bfea4-af92-4165-a9ca-fb3c7e39193d',
      client_name: 'Bitski',
      client_uri: '',
      logo_uri:
        'https://cdn.bitskistatic.com/clients/8cc43503-9ebd-4588-a77f-51ae160d6934/b0cbf12d-dea9-4ee9-8e21-170943ca067c.png',
      owner: '',
      policy_uri: '',
      tos_uri: '',
    },
  ],
};

export const ethSignTransactionTransferERC20 = {
  transaction: {
    id: 'eth-sign-transaction-transfer-erc20',
    kind: 'ETH_SIGN_TRANSACTION',
    consentStatus: 'INCOMPLETE',
    payload: {
      to: '0x3e5296ea36b079265875e52a6eb67799da724ac7',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      value: '0x0',
      data: '0xa22cb4650000000000000000000000001e0049783f008a0085193e00003d00cd54003c710000000000000000000000000000000000000000000000000000000000000001',
      gas: '0xd228',
      gasPrice: '0x2ca573f2c',
    },
    context: {
      chainId: 1,
    },
    activity: {
      primaryEvent: {
        eventType: 'TOKEN_TRANSFER',
        details: {
          from: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
          to: '0x09d482b1e3f31784abbe64e7509c0f08173a7022',
          kind: 'TOKEN',
          token: {
            symbol: 'WETH',
            decimals: 18,
            coinType: 60,
            chainId: 1,
            contractAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
            collection: {
              updatedAt: '2022-09-28T21:20:00',
              name: 'WETH Token',
              image:
                'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-ethereum-square-3e5ae4354e4a324bc7cb981c28f6752e176af01f75dee10d4e37afe5073e438f.png',
            },
          },
          amount: '10000000000000000',
          blockNumber: 0,
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
          logIndex: null,
        },
      },
      additionalEvents: [],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};

export const ethSignTransactionTransferERC721 = {
  transaction: {
    id: 'eth-sign-transaction-transfer-erc721',
    kind: 'ETH_SIGN_TRANSACTION',
    consentStatus: 'INCOMPLETE',
    payload: {
      to: '0x3e5296ea36b079265875e52a6eb67799da724ac7',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      data: '0x23b872dd0000000000000000000000000efb9c52b73d780ee93fdc5d8b0bd4af38842ee3000000000000000000000000749c28abcaa80c194aa985d5662c370e608aa0fa0000000000000000000000000000000000000000000000000000000000001815',
      gas: '0xd228',
      gasPrice: '0x2ca573f2c',
    },
    context: {
      chainId: 5,
    },
    activity: {
      primaryEvent: {
        eventType: 'TOKEN_TRANSFER',
        details: {
          from: '0x75448F078C57dfEEa0A4ee75D59a329c796e60cC',
          to: '0x749c28abcaa80c194aa985d5662c370e608aa0fa',
          kind: 'NFT',
          token: {
            coinType: 60,
            chainId: 1,
            contractAddress: '0xb75f09b4340aeb85cd5f2dd87d31751edc11ed39',
            collection: {
              updatedAt: '2022-10-11T06:12:30.804340594',
              description:
                'Welcome to Candy! Shop our world-class NFTs here on OpenSea or at mlb.candy.com.\n\nCandy is Major League Baseball’s official NFT ecosystem where fans and collectors will be able to purchase, trade, and share officially licensed NFTs to deepen their love of the sport. Candy’s mission is to develop next-generation sports and culture digital assets, providing authentic objects and experiences that deepen fan engagement and connect people to their passions.',
              name: 'Candy Digital',
              image:
                'https://i.seadn.io/gae/SwIlyjjg51ox7Xs6llIioZi2CixlNVFqa2ld23609CYLW7599HZxL3WQMJh0nLDQlYB20kISsEGhgz3MtcqB4uUHdkhB67buYOczAQ?w=500&auto=format',
              openseaSlug: 'candydigital',
              isOpenseaVerified: true,
              isEtherscanVerified: false,
              social: {
                telegramUrl: null,
                twitterUsername: null,
                instagramUsername: null,
                wikiUrl: null,
                mediumUsername: null,
                discordUrl: null,
                externalUrl: null,
              },
              openseaFees: {
                sellerFees: {
                  '0x329a0bb9f238a1e8bfad9a768924abcd4c1a1dbb': 1000,
                },
                openseaFees: {
                  '0x0000a26b00c1f0df003000390027140000faa719': 250,
                },
              },
            },
            id: '0x1815',
            metadata: {
              name: 'Wrigley Field Steel Edition #14/346',
              image:
                'https://cdn.bitskistatic.com/tokens-raw/90419700-e0fd-4e06-8901-8b73de32bcde/82a7f728-8e1a-4b29-af06-c6e84cc7ac6c.jpg',
              animationUrl:
                'https://cdn.bitskistatic.com/tokens-raw/90419700-e0fd-4e06-8901-8b73de32bcde/a7aff753-acf3-467f-ac63-fe14059a41da.mp4',
              description:
                'This open edition MLB Stadium Series NFT collectible features artwork by renowned digital artist S. Preston and pays homage to Wrigley Field’s iconic Ivy Wall. Wrigley Field opened in 1914 and is home to the Chicago Cubs.\n\nNFT Terms: https://bit.ly/3jH6djl',
              externalUrl: 'https://www.bitski.com/Candy',
              marketplaceUrl:
                'https://opensea.io/assets/ethereum/0x7a44228e46cdd37a85c0b07c4043b86c11e8c315/185768422266454438206668338187825610214',
            },
          },
          amount: '1',
          blockNumber: 0,
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
          logIndex: null,
        },
      },
      additionalEvents: [],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};

export const ethSignTransactionTransferERC1155 = {
  transaction: {
    id: 'eth-sign-transaction-transfer-erc1155',
    kind: 'ETH_SIGN_TRANSACTION',
    consentStatus: 'INCOMPLETE',
    payload: {
      to: '0x3e5296ea36b079265875e52a6eb67799da724ac7',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      data: '0x23b872dd0000000000000000000000000efb9c52b73d780ee93fdc5d8b0bd4af38842ee3000000000000000000000000749c28abcaa80c194aa985d5662c370e608aa0fa0000000000000000000000000000000000000000000000000000000000001815',
      gas: '0xd228',
      gasPrice: '0x2ca573f2c',
    },
    context: {
      chainId: 5,
    },
    activity: {
      primaryEvent: {
        eventType: 'TOKEN_TRANSFER',
        details: {
          from: '0x75448F078C57dfEEa0A4ee75D59a329c796e60cC',
          to: '0x749c28abcaa80c194aa985d5662c370e608aa0fa',
          kind: 'NFT',
          token: {
            coinType: 60,
            chainId: 1,
            contractAddress: '0xb75f09b4340aeb85cd5f2dd87d31751edc11ed39',
            collection: {
              updatedAt: '2022-10-11T06:12:30.804340594',
              description:
                'Welcome to Candy! Shop our world-class NFTs here on OpenSea or at mlb.candy.com.\n\nCandy is Major League Baseball’s official NFT ecosystem where fans and collectors will be able to purchase, trade, and share officially licensed NFTs to deepen their love of the sport. Candy’s mission is to develop next-generation sports and culture digital assets, providing authentic objects and experiences that deepen fan engagement and connect people to their passions.',
              name: 'Candy Digital',
              image:
                'https://i.seadn.io/gae/SwIlyjjg51ox7Xs6llIioZi2CixlNVFqa2ld23609CYLW7599HZxL3WQMJh0nLDQlYB20kISsEGhgz3MtcqB4uUHdkhB67buYOczAQ?w=500&auto=format',
              openseaSlug: 'candydigital',
              isOpenseaVerified: true,
              isEtherscanVerified: false,
              social: {
                telegramUrl: null,
                twitterUsername: null,
                instagramUsername: null,
                wikiUrl: null,
                mediumUsername: null,
                discordUrl: null,
                externalUrl: null,
              },
              openseaFees: {
                sellerFees: {
                  '0x329a0bb9f238a1e8bfad9a768924abcd4c1a1dbb': 1000,
                },
                openseaFees: {
                  '0x0000a26b00c1f0df003000390027140000faa719': 250,
                },
              },
            },
            id: '0x1815',
            metadata: {
              name: 'Wrigley Field Steel Edition #14/346',
              image:
                'https://cdn.bitskistatic.com/tokens-raw/90419700-e0fd-4e06-8901-8b73de32bcde/82a7f728-8e1a-4b29-af06-c6e84cc7ac6c.jpg',
              animationUrl:
                'https://cdn.bitskistatic.com/tokens-raw/90419700-e0fd-4e06-8901-8b73de32bcde/a7aff753-acf3-467f-ac63-fe14059a41da.mp4',
              description:
                'This open edition MLB Stadium Series NFT collectible features artwork by renowned digital artist S. Preston and pays homage to Wrigley Field’s iconic Ivy Wall. Wrigley Field opened in 1914 and is home to the Chicago Cubs.\n\nNFT Terms: https://bit.ly/3jH6djl',
              externalUrl: 'https://www.bitski.com/Candy',
              marketplaceUrl:
                'https://opensea.io/assets/ethereum/0x7a44228e46cdd37a85c0b07c4043b86c11e8c315/185768422266454438206668338187825610214',
            },
          },
          amount: '10',
          blockNumber: 0,
          transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
          logIndex: null,
        },
      },
      additionalEvents: [],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};

export const ethSignTransactionGeneralTransaction = {
  transaction: {
    id: 'eth-sign-transaction-transfer-general',
    kind: 'ETH_SIGN_TRANSACTION',
    consentStatus: 'INCOMPLETE',
    payload: {
      to: '0x3e5296ea36b079265875e52a6eb67799da724ac7',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      data: '0x23b872dd0000000000000000000000000efb9c52b73d780ee93fdc5d8b0bd4af38842ee3000000000000000000000000749c28abcaa80c194aa985d5662c370e608aa0fa0000000000000000000000000000000000000000000000000000000000001815',
      gas: '0xd228',
      gasPrice: '0x2ca573f2c',
    },
    context: {
      chainId: 5,
    },
    activity: {
      primaryEvent: {
        eventType: 'UNKNOWN',
      },
      additionalEvents: [
        {
          eventType: 'TOKEN_TRANSFER',
          details: {
            from: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
            to: '0x09d482b1e3f31784abbe64e7509c0f08173a7022',
            kind: 'TOKEN',
            token: {
              symbol: 'WETH',
              decimals: 18,
              coinType: 60,
              chainId: 1,
              contractAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
              collection: {
                updatedAt: '2022-09-28T21:20:00',
                name: 'WETH Token',
                image:
                  'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-ethereum-square-3e5ae4354e4a324bc7cb981c28f6752e176af01f75dee10d4e37afe5073e438f.png',
              },
            },
            amount: '-10000000000000000',
            blockNumber: 0,
            transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
            logIndex: null,
          },
        },
        {
          eventType: 'TOKEN_TRANSFER',
          details: {
            from: '0x75448F078C57dfEEa0A4ee75D59a329c796e60cC',
            to: '0x749c28abcaa80c194aa985d5662c370e608aa0fa',
            kind: 'NFT',
            token: {
              coinType: 60,
              chainId: 1,
              contractAddress: '0xb75f09b4340aeb85cd5f2dd87d31751edc11ed39',
              collection: {
                updatedAt: '2022-10-11T06:12:30.804340594',
                description:
                  'Welcome to Candy! Shop our world-class NFTs here on OpenSea or at mlb.candy.com.\n\nCandy is Major League Baseball’s official NFT ecosystem where fans and collectors will be able to purchase, trade, and share officially licensed NFTs to deepen their love of the sport. Candy’s mission is to develop next-generation sports and culture digital assets, providing authentic objects and experiences that deepen fan engagement and connect people to their passions.',
                name: 'Candy Digital',
                image:
                  'https://i.seadn.io/gae/SwIlyjjg51ox7Xs6llIioZi2CixlNVFqa2ld23609CYLW7599HZxL3WQMJh0nLDQlYB20kISsEGhgz3MtcqB4uUHdkhB67buYOczAQ?w=500&auto=format',
                openseaSlug: 'candydigital',
                isOpenseaVerified: true,
                isEtherscanVerified: false,
                social: {
                  telegramUrl: null,
                  twitterUsername: null,
                  instagramUsername: null,
                  wikiUrl: null,
                  mediumUsername: null,
                  discordUrl: null,
                  externalUrl: null,
                },
                openseaFees: {
                  sellerFees: {
                    '0x329a0bb9f238a1e8bfad9a768924abcd4c1a1dbb': 1000,
                  },
                  openseaFees: {
                    '0x0000a26b00c1f0df003000390027140000faa719': 250,
                  },
                },
              },
              id: '0x1815',
              metadata: {
                name: 'Wrigley Field Steel Edition #14/346',
                image:
                  'https://cdn.bitskistatic.com/tokens-raw/90419700-e0fd-4e06-8901-8b73de32bcde/82a7f728-8e1a-4b29-af06-c6e84cc7ac6c.jpg',
                animationUrl:
                  'https://cdn.bitskistatic.com/tokens-raw/90419700-e0fd-4e06-8901-8b73de32bcde/a7aff753-acf3-467f-ac63-fe14059a41da.mp4',
                description:
                  'This open edition MLB Stadium Series NFT collectible features artwork by renowned digital artist S. Preston and pays homage to Wrigley Field’s iconic Ivy Wall. Wrigley Field opened in 1914 and is home to the Chicago Cubs.\n\nNFT Terms: https://bit.ly/3jH6djl',
                externalUrl: 'https://www.bitski.com/Candy',
                marketplaceUrl:
                  'https://opensea.io/assets/ethereum/0x7a44228e46cdd37a85c0b07c4043b86c11e8c315/185768422266454438206668338187825610214',
              },
            },
            amount: '1',
            blockNumber: 0,
            transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
            logIndex: null,
          },
        },
      ],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};

export const ethSignTransactionGeneralTransactionScroll = {
  transaction: {
    id: 'eth-sign-transaction-transfer-general-scroll',
    kind: 'ETH_SIGN_TRANSACTION',
    consentStatus: 'INCOMPLETE',
    payload: {
      to: '0x3e5296ea36b079265875e52a6eb67799da724ac7',
      from: '0x087d3B34032630bD685a0D3e7B2A08cC26d5CAeF',
      data: '0x23b872dd0000000000000000000000000efb9c52b73d780ee93fdc5d8b0bd4af38842ee3000000000000000000000000749c28abcaa80c194aa985d5662c370e608aa0fa0000000000000000000000000000000000000000000000000000000000001815',
      gas: '0xd228',
      gasPrice: '0x2ca573f2c',
    },
    context: {
      chainId: 5,
    },
    activity: {
      primaryEvent: {
        eventType: 'UNKNOWN',
      },
      additionalEvents: [
        {
          eventType: 'TOKEN_TRANSFER',
          details: {
            from: '0x0efb9c52b73d780ee93fdc5d8b0bd4af38842ee3',
            to: '0x09d482b1e3f31784abbe64e7509c0f08173a7022',
            kind: 'TOKEN',
            token: {
              symbol: 'WETH',
              decimals: 18,
              coinType: 60,
              chainId: 1,
              contractAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
              collection: {
                updatedAt: '2022-09-28T21:20:00',
                name: 'WETH Token',
                image:
                  'https://cdn.bitskistatic.com/images/icons/activity-icons/icon-ethereum-square-3e5ae4354e4a324bc7cb981c28f6752e176af01f75dee10d4e37afe5073e438f.png',
              },
            },
            amount: '-10000000000000000',
            blockNumber: 0,
            transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
            logIndex: null,
          },
        },
        {
          eventType: 'TOKEN_TRANSFER',
          details: {
            from: '0x75448F078C57dfEEa0A4ee75D59a329c796e60cC',
            to: '0x749c28abcaa80c194aa985d5662c370e608aa0fa',
            kind: 'NFT',
            token: {
              coinType: 60,
              chainId: 1,
              contractAddress: '0xb75f09b4340aeb85cd5f2dd87d31751edc11ed39',
              collection: {
                updatedAt: '2022-10-11T06:12:30.804340594',
                description:
                  'Welcome to Candy! Shop our world-class NFTs here on OpenSea or at mlb.candy.com.\n\nCandy is Major League Baseball’s official NFT ecosystem where fans and collectors will be able to purchase, trade, and share officially licensed NFTs to deepen their love of the sport. Candy’s mission is to develop next-generation sports and culture digital assets, providing authentic objects and experiences that deepen fan engagement and connect people to their passions.',
                name: 'Candy Digital',
                image:
                  'https://i.seadn.io/gae/SwIlyjjg51ox7Xs6llIioZi2CixlNVFqa2ld23609CYLW7599HZxL3WQMJh0nLDQlYB20kISsEGhgz3MtcqB4uUHdkhB67buYOczAQ?w=500&auto=format',
                openseaSlug: 'candydigital',
                isOpenseaVerified: true,
                isEtherscanVerified: false,
                social: {
                  telegramUrl: null,
                  twitterUsername: null,
                  instagramUsername: null,
                  wikiUrl: null,
                  mediumUsername: null,
                  discordUrl: null,
                  externalUrl: null,
                },
                openseaFees: {
                  sellerFees: {
                    '0x329a0bb9f238a1e8bfad9a768924abcd4c1a1dbb': 1000,
                  },
                  openseaFees: {
                    '0x0000a26b00c1f0df003000390027140000faa719': 250,
                  },
                },
              },
              id: '0x1815',
              metadata: {
                name: 'Wrigley Field Steel Edition #14/346',
                image:
                  'https://cdn.bitskistatic.com/tokens-raw/90419700-e0fd-4e06-8901-8b73de32bcde/82a7f728-8e1a-4b29-af06-c6e84cc7ac6c.jpg',
                animationUrl:
                  'https://cdn.bitskistatic.com/tokens-raw/90419700-e0fd-4e06-8901-8b73de32bcde/a7aff753-acf3-467f-ac63-fe14059a41da.mp4',
                description:
                  'This open edition MLB Stadium Series NFT collectible features artwork by renowned digital artist S. Preston and pays homage to Wrigley Field’s iconic Ivy Wall. Wrigley Field opened in 1914 and is home to the Chicago Cubs.\n\nNFT Terms: https://bit.ly/3jH6djl',
                externalUrl: 'https://www.bitski.com/Candy',
                marketplaceUrl:
                  'https://opensea.io/assets/ethereum/0x7a44228e46cdd37a85c0b07c4043b86c11e8c315/185768422266454438206668338187825610214',
              },
            },
            amount: '1',
            blockNumber: 0,
            transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
            logIndex: null,
          },
        },
        {
          eventType: 'TOKEN_TRANSFER',
          details: {
            from: '0x75448F078C57dfEEa0A4ee75D59a329c796e60cC',
            to: '0x749c28abcaa80c194aa985d5662c370e608aa0fa',
            kind: 'NFT',
            token: {
              coinType: 60,
              chainId: 1,
              contractAddress: '0xb75f09b4340aeb85cd5f2dd87d31751edc11ed39',
              collection: {
                updatedAt: '2022-10-11T06:12:30.804340594',
                description:
                  'Welcome to Candy! Shop our world-class NFTs here on OpenSea or at mlb.candy.com.\n\nCandy is Major League Baseball’s official NFT ecosystem where fans and collectors will be able to purchase, trade, and share officially licensed NFTs to deepen their love of the sport. Candy’s mission is to develop next-generation sports and culture digital assets, providing authentic objects and experiences that deepen fan engagement and connect people to their passions.',
                name: 'Candy Digital',
                openseaSlug: 'candydigital',
                isOpenseaVerified: true,
                isEtherscanVerified: false,
                social: {
                  telegramUrl: null,
                  twitterUsername: null,
                  instagramUsername: null,
                  wikiUrl: null,
                  mediumUsername: null,
                  discordUrl: null,
                  externalUrl: null,
                },
                openseaFees: {
                  sellerFees: {
                    '0x329a0bb9f238a1e8bfad9a768924abcd4c1a1dbb': 1000,
                  },
                  openseaFees: {
                    '0x0000a26b00c1f0df003000390027140000faa719': 250,
                  },
                },
              },
              id: '0x1815',
              metadata: {
                animationUrl:
                  'https://cdn.bitskistatic.com/tokens-raw/90419700-e0fd-4e06-8901-8b73de32bcde/a7aff753-acf3-467f-ac63-fe14059a41da.mp4',
                description:
                  'This open edition MLB Stadium Series NFT collectible features artwork by renowned digital artist S. Preston and pays homage to Wrigley Field’s iconic Ivy Wall. Wrigley Field opened in 1914 and is home to the Chicago Cubs.\n\nNFT Terms: https://bit.ly/3jH6djl',
                externalUrl: 'https://www.bitski.com/Candy',
                marketplaceUrl:
                  'https://opensea.io/assets/ethereum/0x7a44228e46cdd37a85c0b07c4043b86c11e8c315/185768422266454438206668338187825610214',
              },
            },
            amount: '1',
            blockNumber: 0,
            transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
            logIndex: null,
          },
        },
        {
          eventType: 'TOKEN_TRANSFER',
          details: {
            from: '0x75448F078C57dfEEa0A4ee75D59a329c796e60cC',
            to: '0x749c28abcaa80c194aa985d5662c370e608aa0fa',
            kind: 'NFT',
            token: {
              coinType: 60,
              chainId: 1,
              contractAddress: '0xb75f09b4340aeb85cd5f2dd87d31751edc11ed39',
              collection: {
                updatedAt: '2022-10-11T06:12:30.804340594',
                description:
                  'Welcome to Candy! Shop our world-class NFTs here on OpenSea or at mlb.candy.com.\n\nCandy is Major League Baseball’s official NFT ecosystem where fans and collectors will be able to purchase, trade, and share officially licensed NFTs to deepen their love of the sport. Candy’s mission is to develop next-generation sports and culture digital assets, providing authentic objects and experiences that deepen fan engagement and connect people to their passions.',
                name: 'Candy Digital',
                openseaSlug: 'candydigital',
                isOpenseaVerified: true,
                isEtherscanVerified: false,
                social: {
                  telegramUrl: null,
                  twitterUsername: null,
                  instagramUsername: null,
                  wikiUrl: null,
                  mediumUsername: null,
                  discordUrl: null,
                  externalUrl: null,
                },
                openseaFees: {
                  sellerFees: {
                    '0x329a0bb9f238a1e8bfad9a768924abcd4c1a1dbb': 1000,
                  },
                  openseaFees: {
                    '0x0000a26b00c1f0df003000390027140000faa719': 250,
                  },
                },
              },
              id: '0x1815',
              metadata: {
                name: 'Wrigley Field Steel Edition #16/346',
                animationUrl:
                  'https://cdn.bitskistatic.com/tokens-raw/90419700-e0fd-4e06-8901-8b73de32bcde/a7aff753-acf3-467f-ac63-fe14059a41da.mp4',
                description:
                  'This open edition MLB Stadium Series NFT collectible features artwork by renowned digital artist S. Preston and pays homage to Wrigley Field’s iconic Ivy Wall. Wrigley Field opened in 1914 and is home to the Chicago Cubs.\n\nNFT Terms: https://bit.ly/3jH6djl',
                externalUrl: 'https://www.bitski.com/Candy',
                marketplaceUrl:
                  'https://opensea.io/assets/ethereum/0x7a44228e46cdd37a85c0b07c4043b86c11e8c315/185768422266454438206668338187825610214',
              },
            },
            amount: '1',
            blockNumber: 0,
            transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
            logIndex: null,
          },
        },
        {
          eventType: 'TOKEN_TRANSFER',
          details: {
            from: '0x75448F078C57dfEEa0A4ee75D59a329c796e60cC',
            to: '0x749c28abcaa80c194aa985d5662c370e608aa0fa',
            kind: 'NFT',
            token: {
              coinType: 60,
              chainId: 1,
              contractAddress: '0xb75f09b4340aeb85cd5f2dd87d31751edc11ed39',
              collection: {
                updatedAt: '2022-10-11T06:12:30.804340594',
                description:
                  'Welcome to Candy! Shop our world-class NFTs here on OpenSea or at mlb.candy.com.\n\nCandy is Major League Baseball’s official NFT ecosystem where fans and collectors will be able to purchase, trade, and share officially licensed NFTs to deepen their love of the sport. Candy’s mission is to develop next-generation sports and culture digital assets, providing authentic objects and experiences that deepen fan engagement and connect people to their passions.',
                name: 'Candy Digital',
                image:
                  'https://i.seadn.io/gae/SwIlyjjg51ox7Xs6llIioZi2CixlNVFqa2ld23609CYLW7599HZxL3WQMJh0nLDQlYB20kISsEGhgz3MtcqB4uUHdkhB67buYOczAQ?w=500&auto=format',
                openseaSlug: 'candydigital',
                isOpenseaVerified: true,
                isEtherscanVerified: false,
                social: {
                  telegramUrl: null,
                  twitterUsername: null,
                  instagramUsername: null,
                  wikiUrl: null,
                  mediumUsername: null,
                  discordUrl: null,
                  externalUrl: null,
                },
                openseaFees: {
                  sellerFees: {
                    '0x329a0bb9f238a1e8bfad9a768924abcd4c1a1dbb': 1000,
                  },
                  openseaFees: {
                    '0x0000a26b00c1f0df003000390027140000faa719': 250,
                  },
                },
              },
              id: '0x1815',
              metadata: {
                name: 'Wrigley Field Steel Edition #17/346',
                image:
                  'https://cdn.bitskistatic.com/tokens-raw/90419700-e0fd-4e06-8901-8b73de32bcde/82a7f728-8e1a-4b29-af06-c6e84cc7ac6c.jpg',
                animationUrl:
                  'https://cdn.bitskistatic.com/tokens-raw/90419700-e0fd-4e06-8901-8b73de32bcde/a7aff753-acf3-467f-ac63-fe14059a41da.mp4',
                description:
                  'This open edition MLB Stadium Series NFT collectible features artwork by renowned digital artist S. Preston and pays homage to Wrigley Field’s iconic Ivy Wall. Wrigley Field opened in 1914 and is home to the Chicago Cubs.\n\nNFT Terms: https://bit.ly/3jH6djl',
                externalUrl: 'https://www.bitski.com/Candy',
                marketplaceUrl:
                  'https://opensea.io/assets/ethereum/0x7a44228e46cdd37a85c0b07c4043b86c11e8c315/185768422266454438206668338187825610214',
              },
            },
            amount: '1',
            blockNumber: 0,
            transactionHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
            logIndex: null,
          },
        },
      ],
    },
  },
  clients: [
    {
      client_id: '7936b6f4-b6ca-43a4-9b7d-b54e410a8cb2',
      client_name: 'Example Dapp',
      client_uri: 'https://example.com',
      logo_uri: null,
      tos_uri: 'https://example.com/terms',
      privacy_uri: 'https://example.com/privacy',
    },
  ],
};
