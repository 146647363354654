import { dev } from '$app/environment';
import { allTransactions } from '$lib/mocks/handlers';

/** @type {import('@sveltejs/kit').ParamMatcher} */
export function match(param) {
  if (
    (dev || import.meta.env.VITE_TEST_PREVIEW) &&
    allTransactions.find((t) => t.transaction.id === param)
  ) {
    return true;
  }

  return /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi.test(
    param,
  );
}
